import { SurveyResponseDto } from "Api/Features/SurveyResponses/Dtos/SurveyResponseDto";
import { MOMENT_MONTH_YEAR_DISPLAY_FORMAT } from "Models/Constants";
import moment from "moment";

export interface GroupedByMonthData {
    groupString: string,
    monthYear: string,
    surveys: SurveyResponseDto[]
}

export function groupByMonth(orderedData: SurveyResponseDto[]): GroupedByMonthData[] {
    const groupedByMonth: GroupedByMonthData[] = [];
    //data is ordered newest first.
    orderedData.map(survey => {
        const surveyDate = moment.utc(survey.date);
        //if month of survey is same as current group; add it.
        if (
            groupedByMonth[groupedByMonth.length - 1]?.monthYear &&
            surveyDate.clone().utc().format(MOMENT_MONTH_YEAR_DISPLAY_FORMAT) ===
                groupedByMonth[groupedByMonth.length - 1]?.monthYear
        ) {
            groupedByMonth[groupedByMonth.length - 1].surveys.push(survey);
        }
        //different month than current group. Create a new group and add survey in group
        else {
            groupedByMonth[groupedByMonth.length] = {
                groupString: surveyDate.isSame(moment().utc(), 'month')
                    ? 'This Month'
                    : surveyDate.format(MOMENT_MONTH_YEAR_DISPLAY_FORMAT),
                monthYear: surveyDate.clone().utc().format(MOMENT_MONTH_YEAR_DISPLAY_FORMAT),
                surveys: [survey],
            };
        }
    })
    return groupedByMonth;
}