import React, { FunctionComponent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Menu } from 'antd';
import './patient-navigation.less';

interface MenuItem {
    key: string;
    label: string;
    disabled?: boolean;
}

const PatientNavigation: FunctionComponent = observer(() => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const menuItems = (): MenuItem[] => {
        const items = [
            {
                key: `/patients/${id}/mpn10`,
                label: 'MPN 10',
            },
            {
                key: `/patients/${id}/other-questionnaires`,
                label: 'Other Questionnaires',
            },
        ];

        return items;
    };

    const onMenuItemClicked = (param): void => {
        if (history.location.pathname !== param.key) {
            history.push(param.key);
        }
    };

    const getSelectedKeys = (): string[] => {
        const path = history.location.pathname;
        return menuItems()
            .filter((x) => {
                return path.includes(x.key);
            })
            .map((x) => {
                return x.key;
            });
    };

    return (
        <div className="PatientNavigation">
            <Menu
                theme="light"
                mode="horizontal"
                selectedKeys={getSelectedKeys()}
                onClick={onMenuItemClicked}
            >
                {menuItems().map((item) => (
                    <Menu.Item key={item.key}>{item.label}</Menu.Item>
                ))}
            </Menu>
        </div>
    );
});

export default PatientNavigation;
