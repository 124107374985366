import { Button, Col, Form, Input, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { CreateAdminUserRequestDto } from 'Api/Features/Users/Dtos/CreateAdminUserRequestDto';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import { UpdateAdminUserRequestDto } from 'Api/Features/Users/Dtos/UpdateAdminUserRequestDto';
import { UserRequestRoleDto } from 'Api/Features/Users/Dtos/UserRequestRoleDto';
import BaseModal from 'Components/base-modal/base-modal';
import ImagePicker from 'Components/image-picker/image-picker';
import Select, { SelectOption } from 'Components/select/select';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import React, { FunctionComponent, useEffect } from 'react';
import { useState } from 'react';
import { EditAdministratorSchema } from 'Schemas/EditAdministratorSchema';
import { UserService } from 'Services/UserService';
import { ManagerUserDetailsExtended } from 'Stores/UserStore';


const formGutter: [Gutter, Gutter] = [40, 0];

interface EditAdministratorModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    user?: ManagerUserDetailsExtended;
}

const EditAdministratorModal: FunctionComponent<EditAdministratorModalProps> = ({ visible, onComplete, user }) => {
    //#region Hooks
    const [errors, validateForm, resetErrors] = useFormValidation(EditAdministratorSchema);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [form] = Form.useForm();
    const userService = useService(UserService);
    const [imageUrl, setImageUrl] = useState<string | undefined>(user?.imageUrl ?? undefined);
    const [base64, setBase64] = useState<string>();
    
    //#endregion

    const roleOptions = (): SelectOption[] => {
        const options = Object.keys(ManagementRoleDto)
            .filter((role) => role !== ManagementRoleDto.Physician)
            .map((role) => ({
                id: role,
                name:
                    role === ManagementRoleDto.SuperAdministrator
                        ? 'Super administrator'
                        : role,
            }));
        return options;
    };

    //#region Effects
    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                role: user.managementRoles?.[0]?.name
            });
        }
    }, [user, form]);
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: null, 
                title: 'Are you sure you want to leave?',
                message: `Contents on this page won't be saved`,
                positiveText: 'Yes',
                negativeText: 'No',
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const data = form.getFieldsValue();

        if (!(await validateForm(data))) return;
        try {
            globalLoadingStore.addLoading();
            if(user !== undefined) {
                const resquest: UpdateAdminUserRequestDto = {
                    ...data,
                    image: base64
                        ? ({
                              uploadBase64: base64,
                          } as UpdateFileRequestDto)
                        : null,
                    managementRoles: [
                        {
                            name: data.role,
                        } as UserRequestRoleDto,
                    ],
                };
                await userService.updateAdminUser(user.id!, resquest);
            }
            else {
                const resquest: CreateAdminUserRequestDto = {
                    ...data,
                    image: base64
                        ? ({
                              uploadBase64: base64,
                          } as UpdateFileRequestDto)
                        : null,
                    managementRoles: [
                        {
                            name: data.role,
                        } as UserRequestRoleDto,
                    ],
                };
                await userService.createAdminUser(resquest);
            }

            toastStore.toast({
                type: 'success',
                messageKey: `Administrator ${
                    user !== undefined ? 'updated' : 'created'
                } successfully`,
            });
            dismiss(true);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={user !== undefined ? 'Edit Administrator' : 'Create Administrator'}
            className="FormModal"
            onCancel={exit}
        >
            <div className="EditAdministratorModal">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <ImagePicker
                        onFileChange={(imageUrl, base64) => {
                            setImageUrl(imageUrl);
                            setBase64(base64);
                        }}
                        imageUrl={imageUrl}
                    />

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="firstName"
                                label={'First Name'}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem errors={errors} name="lastName" label={'Last Name'}>
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem errors={errors} name="email" label={'Email'}>
                                <Input disabled={user !== undefined} />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem errors={errors} name="role" label={'Role'}>
                                <Select
                                    options={roleOptions()}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {'Save Changes'}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(EditAdministratorModal);
