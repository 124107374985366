import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import './index.less';
import { useStores } from 'Hooks';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';

const NotFoundPage: FunctionComponent = () => {
    const { userStore } = useStores();
    return (
        <div className="NotFoundPage">
            <div className="e404">{'404'}</div>
            <div className="H1-bold not-found">{'Page Not Found'}</div>
            <div className="Paragraph text">
                {
                    'The page you are looking for might have been removed, had its name changed or is simply not available'
                }
            </div>
            <Button type="primary" className="back-to-site">
                <Link
                    to={
                        userStore.userRole === ManagementRoleDto.Physician
                            ? '/patients'
                            : '/doctors'
                    }
                >
                    {'Go back'}
                </Link>
            </Button>
        </div>
    );
};

export default NotFoundPage;
