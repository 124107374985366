import { Modal, Typography } from 'antd';
import { QuestionTypeDto } from 'Api/Features/SurveyResponses/Dtos/QuestionTypeDto';
import { QuestionDto } from 'Api/Features/Surveys/Dtos/QuestionDto';
import { Close } from 'Components/icons';
import React, { FunctionComponent } from 'react';
import { theme } from 'Style/theme';
import './survey-question-explanation-modal.less';

const { Title } = Typography;

export interface ConfirmationModalProps {
    question?: QuestionDto
    onClose: () => void;
    visible: boolean;
}

const SurveyQuestionExplanationModal: FunctionComponent<ConfirmationModalProps> = ({
    question,
    onClose,
    visible,
}) => {
    return (
        <Modal
            visible={visible}
            className="SurveyQuestionExplanationModal"
            width={780}
            onCancel={onClose}
            closeIcon={<Close fill={theme.black} />}
        >
            <Title level={2} className="title">
                {question?.titleEN}
            </Title>
            <div className="description">{question?.descriptionEN}</div>

            {question?.type === QuestionTypeDto.Likert && (
                <div className="scale-container">
                    <div className="scale">
                        <div className="number">{'0'}</div>
                        <div className="text">{'(absent)'}</div>
                    </div>

                    <div className="up-to">{'Up To'}</div>

                    <div className="scale">
                        <div className="number">{'10'}</div>
                        <div className="text">{'(worst imaginable)'}</div>
                    </div>
                </div>
            )}

            {(question?.type === QuestionTypeDto.MultiChoice ||
                question?.type === QuestionTypeDto.SingleChoice) && (
                <div className="possible-answer-container">
                    <ul>
                        {question?.choices?.map((x) => (
                            <li key={x?.id}>{x?.descriptionEN}</li>
                        ))}
                    </ul>
                </div>
            )}
        </Modal>
    );
};

export default SurveyQuestionExplanationModal;
