import moment from "moment";

export enum TimeframeFilterEnum {
  ZeroTwoWeeks = "0-2 weeks ago",
  TwoFourWeeks = "2-4 weeks ago",
  OneThreeMonths = "1-3 months ago",
  ThreeSixMonths = "3-6 months ago",
  MoreSixMonths = "More than 6 months ago",
  All = 'All MPN 10 answer timelapse'
}

interface Timeframe {
  start?: string;
  end?: string;
}

export const TimeFrameFilterToTimeStrings = (timeframe: TimeframeFilterEnum): Timeframe => {
  const currentTime = moment();
  const result: Timeframe = {};

  switch (timeframe) {
    case TimeframeFilterEnum.ZeroTwoWeeks:
      result.start = moment(currentTime).subtract(2, "week").format();
      result.end = currentTime.format();
      break;
    case TimeframeFilterEnum.TwoFourWeeks:
      result.start = moment(currentTime).subtract(4, "week").format();
      result.end = moment(currentTime).subtract(2, "week").format();
      break;
    case TimeframeFilterEnum.OneThreeMonths:
      result.start = moment(currentTime).subtract(3, "month").format();
      result.end = moment(currentTime).subtract(1, "month").format();
      break;
    case TimeframeFilterEnum.ThreeSixMonths:
      result.start = moment(currentTime).subtract(6, "month").format();
      result.end = moment(currentTime).subtract(3, "month").format();
      break;
    case TimeframeFilterEnum.MoreSixMonths:
      result.start = undefined;
      result.end = moment(currentTime).subtract(6, "month").format();
      break;
    case TimeframeFilterEnum.All:
      result.start = undefined;
      result.end = undefined;
      break;
  }

  return result;
}