import React, { FunctionComponent, ReactNode, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { createContext } from 'react';
import { useService, useStores } from 'Hooks';
import { useHistory, useParams } from 'react-router-dom';
import { PatientService } from 'Services/PatientService';
import { PatientDto } from 'Api/Features/Patients/Dtos/PatientDto';
import PatientHeader from 'Components/patient-header/patient-header';
import { ChevronLeft, List, Mail, Print, Trash, User } from 'Components/icons';
import { theme } from 'Style/theme';
import DropdownButton, { DropdownButtonMenuItem } from 'Components/dropdown-button/dropdown-button';
import PatientNavigation from 'Components/patient-navigation/patient-navigation';
import TransferPatientModal from './transfer-patient-modal';
import EditPatientModal from './edit-patient-modal';
import { PhysicianPatientStatusDto } from 'Api/Features/Patients/Dtos/PhysicianPatientStatusDto';
import { UserService } from 'Services/UserService';
import { ResendAccountConfirmationRequestDto } from 'Api/Features/Users/Dtos/ResendAccountConfirmationRequestDto';
import PrintStatisticsModal from './print-statistics-modal';

//interface PatientContext extends PatientDto if ever need be.
type PatientContext = PatientDto;

export const PatientContext = createContext<PatientContext | undefined>(undefined);

const PatientDetails: FunctionComponent = observer(({ children }) => {
    const { id } = useParams<{ id: string }>();
    const [patient, setPatient] = useState<PatientContext>();
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const patientService = useService(PatientService);
    const userService = useService(UserService);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [transferPatientModalOpen, setTransferPatientModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [printModalOpen, setPrintModalOpen] = useState(false);

    const fetchPatient: any = useCallback(async () => {
        globalLoadingStore.addLoading();
        setLoading(true);
        try {
            // call api
            const response = await patientService.getPatient(id);
            if (response) setPatient(response);
        } finally {
            globalLoadingStore.removeLoading();
            setLoading(false);
        }
    }, [globalLoadingStore]);

    useEffect(() => {
        fetchPatient();
    }, [fetchPatient]);

    const returnButton = (): ReactNode => {
        return (
            <div className="return-btn" onClick={() => history.push('/patients')}>
                <ChevronLeft fill={theme['primary-color']} width={14} height={14} />
                {'Patients'}
            </div>
        );
    };

    const resendInvitation = async(): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const request: ResendAccountConfirmationRequestDto = {
                email: patient?.email
            }
            await userService.resendAccountConfirmation(request);
            toastStore.toast({
                type: 'success',
                message: 'Email sent'
            })
        } finally {
            globalLoadingStore.removeLoading();
        }
    }

    const deletePatient = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: null,
                title: 'Are you sure?',
                message: 'This action will permanently delete this patient.',
                positiveText: 'Delete',
                negativeText: 'Cancel',
            }))
        )
            return;

        try {
            globalLoadingStore.addLoading();
            await patientService.deletePatient(id);
            toastStore.toast({
                type: 'success',
                message: 'Patient deleted successfully',
            });
            history.push('/patients');
        } catch (e) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const menuItems = useCallback((): DropdownButtonMenuItem[] => {
        const items = [
            {
                text: 'Edit Patient',
                icon: <List width={20} height={20} fill={theme['primary-color']} />,
                onClick: () => setEditModalOpen(true),
            },
            {
                text: 'Transfer Patient',
                icon: <User width={20} height={20} fill={theme['primary-color']} />,
                onClick: () => setTransferPatientModalOpen(true),
            },
            {
                text: 'Print Statistics',
                icon: <Print width={20} height={20} fill={theme['primary-color']} />,
                onClick: () => setPrintModalOpen(true),
            },
            {
                text: 'Delete Patient',
                icon: <Trash width={20} height={20} fill={theme['primary-color']} />,
                onClick: () => deletePatient(),
            },
        ];
        if (
            patient?.physicianPatientStatus === PhysicianPatientStatusDto.PatientInvitationPending
        ) {
            items.splice(1, 0, {
                text: 'Resend Invitation',
                icon: <Mail width={20} height={20} fill={theme['primary-color']} />,
                onClick: () => resendInvitation(),
            });
        }
        return items;
    }, [patient]);

    return (
        <PatientContext.Provider value={patient}>
            <div className="PatientDetails">
                <PatientHeader
                    patient={patient}
                    returnButton={returnButton()}
                    loading={loading}
                    action={<DropdownButton menuItems={menuItems()} />}
                />
                <PatientNavigation />
                {children}
            </div>

            {transferPatientModalOpen && (
                <TransferPatientModal
                    visible={transferPatientModalOpen}
                    onComplete={(success: boolean): void => {
                        if (success) history.push('/patients');
                        setTransferPatientModalOpen(false);
                    }}
                    currentDoctorId={patient?.physician?.id}
                />
            )}

            {editModalOpen && (
                <EditPatientModal
                    visible={editModalOpen}
                    onComplete={(success: boolean): void => {
                        if (success) fetchPatient();
                        setEditModalOpen(false);
                    }}
                    user={patient}
                />
            )}

            {printModalOpen && (
                <PrintStatisticsModal
                    visible={printModalOpen}
                    onComplete={(): void => {
                        setPrintModalOpen(false);
                    }}
                    patientId={id}
                />
            )}
        </PatientContext.Provider>
    );
});

export default PatientDetails;
