import { Button, Checkbox, Col, DatePicker, Form, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import Title from 'antd/lib/typography/Title';
import { ExportSurveyResponseReportRequestDto } from 'Api/Features/SurveyResponses/Dtos/ExportSurveyResponseReportRequestDto';
import { SurveyDto } from 'Api/Features/Surveys/Dtos/SurveyDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Calendar, Close } from 'Components/icons';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { ExportSurveysSchema } from 'Schemas/ExportSurveysSchema';
import { SurveyResponseService } from 'Services/SurveyResponseService';
import { SurveyService } from 'Services/SurveyService';
import { theme } from 'Style/theme';
import { showFile } from 'Utils/Blob';
import './export-data-modal.less';

interface ExportDataModalProps {
    visible: boolean;
    onComplete: () => void;
}

const formGutter: [Gutter, Gutter] = [40, 0];

const ExportDataModal: FunctionComponent<ExportDataModalProps> = ({ visible, onComplete }) => {
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(ExportSurveysSchema);
    const surveyService = useService(SurveyService);
    const surveyResponseService = useService(SurveyResponseService);
    const { confirmationModalStore, globalLoadingStore, toastStore } = useStores();
    const [allTimeCheckboxState, setAllTimeCheckboxState] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState<moment.Moment | null>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<moment.Moment | null>(null);

    const pageSize = 25;
    const [surveysCurrentPage, setSurveysCurrentPage] = useState(0);
    const [surveysSearchResults, setSurveysSearchResults] = useState<SurveyDto[]>([]);
    const [surveysSearchTerm, setSurveysSearchTerm] = useState('');
    const [surveysMaxResults, setSurveysMaxResults] = useState(false);
    const [surveyOptions, setSurveysOptions] = useState<SelectCustomOption[]>([]);
    const [selectedSurveyOptions, setSelectedSurveysOptions] = useState<SelectCustomOption[]>([]);
    const [selectedSurveyIds, setSelectedSurveyIds] = useState<string[]>([]);
    const [selectLoading, setSelectLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    const resetSurveysSearch = (): void => {
        setSurveysCurrentPage(0);
        setSurveysSearchResults([]);
        setSurveysSearchTerm('');
        setSurveysMaxResults(false);
    };

    const handleSurveyKeywordsChange = useCallback((value: string): void => {
        resetSurveysSearch();
        setSurveysSearchTerm(value);
    }, []);

    const searchSurveys = async (page: number, searchTerm: string): Promise<SurveyDto[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
        };
        const [results, totalItemCount] = await surveyService.getSurveys(args);

        if (results.length + pageSize * page >= totalItemCount) {
            setSurveysMaxResults(true);
        }
        setSelectLoading(false);
        return results;
    };

    const debounceSurveySearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchSurveys(page, searchTerm).then((results) => {
                setSurveysSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, 300)
    );

    const handleSurveysMenuScrollToBottom = (): void => {
        if (!surveysMaxResults) {
            setSurveysCurrentPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        const searchResults = surveysSearchResults?.map(
            (x: SurveyDto) =>
                ({
                    value: x?.id,
                    label: x?.titleEN,
                    checkBox: true,
                } as SelectCustomOption)
        );

        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedSurveyOptions);

        setSurveysOptions(merged);
        if (selectAll) setSelectedSurveyIds(surveysSearchResults.map((survey) => survey.id!));
    }, [surveysSearchResults, selectedSurveyOptions]);

    useEffect(() => {
        setSelectLoading(true);
        debounceSurveySearch.current(surveysCurrentPage, surveysSearchTerm);
    }, [surveysCurrentPage, surveysSearchTerm]);

    useEffect(() => {
        if (selectAll) {
            setSelectedSurveyIds(surveyOptions.map((option) => option.value));
        }
    }, [selectAll]);

    const handleSelectAllClick = () => {
        setSelectAll(true);
    };

    const handleClearClick = () => {
        setSelectedSurveyIds([]);
        setSelectAll(false);
    };

    useEffect(() => {
        if(selectedStartDate && selectedEndDate && selectedStartDate.isAfter(selectedEndDate)) {
            setSelectedEndDate(null);
            form.setFieldsValue({endDate: null})
        }
    }, [selectedStartDate, selectedEndDate])

    const dismiss = (): void => {
        onComplete();
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: null,
                title: 'Are you sure you want to leave?',
                message: `Contents on this page won't be saved`,
                positiveText: 'Yes',
                negativeText: 'No',
            }))
        )
            return;
        dismiss();
    };

    const submit = async () => {
        const formData = form.getFieldsValue();
        
        const request: ExportSurveyResponseReportRequestDto = {
            ...formData,
            surveyIds: selectedSurveyIds,
            startDate: allTimeCheckboxState ? undefined : formData.startDate ?? null,
            endDate: allTimeCheckboxState ? undefined : formData.endDate ?? null,
            allTime: allTimeCheckboxState,
        };

        if (!(await validateForm(request))) return;
        try {
            globalLoadingStore.addLoading();

            const document = await surveyResponseService.exportSurveyResponse(request);
            showFile(document, 'Survey_responses');
            dismiss();
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <BaseModal
            visible={visible}
            className="ExportDataModal ConfirmationModal"
            width={784}
            onCancel={exit}
            closeIcon={<Close fill={theme.black} />}
        >
            <Form layout="vertical" onFinish={submit} form={form}>
                <Title level={2} className="title">
                    {'Export Data'}
                </Title>
                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem className="no-x-error" errors={errors} name="surveyIds">
                            <SelectCustom
                                options={surveyOptions}
                                strongLabel={true}
                                placeholder={'Choose Questionnaires'}
                                onKeywordsChange={handleSurveyKeywordsChange}
                                onMenuScrollToBottom={handleSurveysMenuScrollToBottom}
                                onChange={(values: any): void => {
                                    if (values === '') setSelectedSurveyIds([]);
                                    else {
                                        setSelectedSurveyIds(values?.map((x: any) => x.value));
                                        setSelectedSurveysOptions(values);
                                    }
                                    setSelectAll(false);
                                }}
                                hideSelectedOptions={false}
                                selected={selectedSurveyIds}
                                isLoading={selectLoading}
                                isMulti
                                closeMenuOnSelect={false}
                                multiSelectableAction
                                onSelectAllClick={handleSelectAllClick}
                                onClearClick={handleClearClick}
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Row gutter={formGutter}>
                    <Col span={24}>
                        <ValidatedFormItem>
                            <Checkbox
                                checked={allTimeCheckboxState}
                                onChange={(e) => setAllTimeCheckboxState(e.target.checked)}
                            >
                                <span className="Paragraph">{'Alltime Data'}</span>
                            </Checkbox>
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Row gutter={formGutter}>
                    <Col span={12}>
                        <ValidatedFormItem label="Start Date" name="startDate" errors={errors}>
                            <DatePicker
                                suffixIcon={<Calendar fill={theme['primary-color']} />}
                                disabled={allTimeCheckboxState}
                                onChange={(value) => setSelectedStartDate(value)}
                                allowClear={false}
                            />
                        </ValidatedFormItem>
                    </Col>
                    <Col span={12}>
                        <ValidatedFormItem label="End Date" name="endDate" errors={errors}>
                            <DatePicker
                                suffixIcon={<Calendar fill={theme['primary-color']} />}
                                disabled={allTimeCheckboxState}
                                onChange={(value) => setSelectedEndDate(value)}
                                disabledDate={(current) => selectedStartDate ? current < selectedStartDate : false}
                                allowClear={false}
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>
                 
                <div className="actions">
                    <Button type="default" className="negative secondary" onClick={() => exit()}>
                        {'Cancel'}
                    </Button>
                    <Button type="primary" className="positive" htmlType="submit">
                        {'Download'}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
};

export default React.memo(ExportDataModal);
