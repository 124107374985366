import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { CreatePatientRequestDto } from 'Api/Features/Patients/Dtos/CreatePatientRequestDto';
import { DiagnosisDto } from 'Api/Features/Patients/Dtos/DiagnosisDto';
import { PatientDto } from 'Api/Features/Patients/Dtos/PatientDto';
import { TreatmentTypeDto } from 'Api/Features/Patients/Dtos/TreatmentTypeDto';
import { UpdatePatientRequestDto } from 'Api/Features/Patients/Dtos/UpdatePatientRequestDto';
import BaseModal from 'Components/base-modal/base-modal';
import { Calendar } from 'Components/icons';
import Select from 'Components/select/select';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { MOMENT_YEAR_FORMAT } from 'Models/Constants';
import { DiagnosisToString } from 'Models/Diagnosis/DiagnosisToString';
import { TreatmentTypeToString } from 'Models/TreatmentType/TreatmentTypeToString';
import moment from 'moment';
import React, { FunctionComponent, useEffect } from 'react';
import { EditPatientSchema } from 'Schemas/EditPatientSchema';
import { PatientService } from 'Services/PatientService';
import { theme } from 'Style/theme';

const formGutter: [Gutter, Gutter] = [40, 0];

interface EditPatientModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    user?: PatientDto;
}

const EditPatientModal: FunctionComponent<EditPatientModalProps> = ({
    visible,
    onComplete,
    user,
}) => {
    //#region Hooks
    const [errors, validateForm, resetErrors] = useFormValidation(EditPatientSchema);
    const { globalLoadingStore, toastStore, confirmationModalStore, userStore } = useStores();
    const patientService = useService(PatientService);
    const [form] = Form.useForm();

    //#endregion

    //#region Effects
    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                treatmentType: user.treatmentType,
                diagnosis: user.diagnosis,
                birthYear: user.birthYear ? moment(`${user.birthYear}-01-01`) : undefined,
            });
        }
    }, [user, form]);
    //#endregion

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: null,
                title: 'Are you sure you want to leave?',
                message: `Contents on this page won't be saved`,
                positiveText: 'Yes',
                negativeText: 'No',
            }))
        )
            return;
        dismiss();
    };

    const submit = async (): Promise<void> => {
        const data = form.getFieldsValue();

        if (!(await validateForm(data))) return;
        try {
            globalLoadingStore.addLoading();

            if (user !== undefined) {
                const request: UpdatePatientRequestDto = {
                    ...data,
                    birthYear: moment(data.birthYear).format(MOMENT_YEAR_FORMAT),
                };
                await patientService.updatePatient(user.id!, request); 
            } else {
                const request: CreatePatientRequestDto = {
                    ...data,
                    birthYear: moment(data.birthYear).format(MOMENT_YEAR_FORMAT),
                    physicianId: userStore.userInfo?.id,
                    isPatientInvitation: true
                };
                await patientService.createPatient(request);
            }

            toastStore.toast({
                type: 'success',
                messageKey: `Patient ${user !== undefined ? 'updated successfully' : 'created. An invitation was also sent to the patient.'}`,
            });
            dismiss(true);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    //#region Render
    return (
        <BaseModal
            visible={visible}
            title={user ? 'Edit Patient' : 'Create Patient'}
            className="FormModal"
            onCancel={exit}
        >
            <div className="EditPatientModal">
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="firstName"
                                label={'First Name'}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem errors={errors} name="lastName" label={'Last Name'}>
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="birthYear"
                                label={'Year of Birth'}
                            >
                                <DatePicker
                                    picker={'year'}
                                    disabledDate={(date) => moment().isBefore(date)}
                                    allowClear={false}
                                    suffixIcon={<Calendar fill={theme['primary-color']} />}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="treatmentType"
                                label={'Treatment'}
                            >
                                <Select
                                    options={Object.keys(TreatmentTypeDto).map((type) => ({
                                        id: type,
                                        name: TreatmentTypeToString(TreatmentTypeDto[type]),
                                    }))}
                                    placeHolder={'Choose'}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem errors={errors} name="diagnosis" label={'Diagnosis'}>
                                <Select
                                    options={Object.keys(DiagnosisDto).map((diagnosis) => ({
                                        id: diagnosis,
                                        name: DiagnosisToString(DiagnosisDto[diagnosis]),
                                    }))}
                                    placeHolder={'Choose'}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem errors={errors} name="email" label={'Email'}>
                                <Input disabled={user !== undefined} />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="actions">
                        <Button
                            type="default"
                            className="secondary negative"
                            htmlType="button"
                            onClick={(): Promise<void> => exit()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button type="primary" className="positive" htmlType="submit">
                            {'Save Changes'}
                        </Button>
                    </div>
                </Form>
            </div>
        </BaseModal>
    );
    //#endregion
};

export default React.memo(EditPatientModal);
