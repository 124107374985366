import { Col, Row } from 'antd';
import {  List } from 'Components/icons';
import React, { FunctionComponent } from 'react';
import { theme } from 'Style/theme';
import'./index.less';

const NoQuestionnaireData: FunctionComponent = () => {

    return (
        <div className="NoQuestionnaireData">
            <Row>
                <Col span={24}>
                    <div className="no-data-container">
                        <List fill={theme['grayscale-500']} width={45} height={45} />
                        <div className="title">{'No Data Yet'}</div>
                        <div className="text">
                            {'This user has not completed a questionnaire in this time period.'}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default NoQuestionnaireData