import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Content } from 'antd/lib/layout/layout';
import { Col, Row } from 'antd';
import { useService, useStores } from 'Hooks';
import { SurveyResponseService } from 'Services/SurveyResponseService';
import { useState } from 'react';
import { SurveyResponseDto } from 'Api/Features/SurveyResponses/Dtos/SurveyResponseDto';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { GetSurveyResponsesRequestDto } from 'Api/Features/SurveyResponses/Dtos/GetSurveyResponsesRequestDto';
import { useParams } from 'react-router-dom';
import './other-questionnaires.less';
import NoQuestionnaireData from 'Components/no-questionnaire-data';
import DateRangeDropdown, { DateRangeDropdownOption, getMinDateFilter } from 'Components/date-range-dropdown';
import PatientOtherQuestionnaireHistory from 'Components/patient-other-questionnaire-history/patient-other-questionnaire-history';
import { GraphIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import { MPN10_SURVEY_ID } from 'Models/Constants';

const OtherQuestionnaires: FunctionComponent = observer(() => {
    const { globalLoadingStore, toastStore } = useStores();
    const { id } = useParams<{ id: string }>();
    const surveyResponseService = useService(SurveyResponseService);
    const [data, setData] = useState<SurveyResponseDto[]>([]);

    //date range dropdown
    const [selectedDateRangeType, setSelectedDateRangeType] = useState<DateRangeDropdownOption>(
        DateRangeDropdownOption.Last3Months
    );

    const fetchSurveyAnswers = useCallback(
        async (params: { surveyIds?: string[]; minDate?: string }) => {
            try {
                globalLoadingStore.addLoading();
                let currentPage = 0;
                let fetchedAll = false;
                const result: SurveyResponseDto[] = [];
                while (!fetchedAll) {
                    const request: GetSurveyResponsesRequestDto = {
                        patientIds: [id],
                        surveyIds: params.surveyIds,
                        minDate: params.minDate,
                        page: currentPage,
                        pageSize: 50
                    };
                    const [response, totalItemCount] = await surveyResponseService.getSurveyResponses(request);
                    result.push(...response)
                    currentPage++;
                    fetchedAll = result.length === totalItemCount;
                }
                if (result) {
                    //filter out MPN10 answers
                    const surveys = result.filter((survey) => survey.surveyId !== MPN10_SURVEY_ID);
                    setData(surveys);
                }
            } catch (e) {
                if (!e.treated) {
                    toastStore.serverError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        []
    );

    useEffect(() => {
        fetchSurveyAnswers({
            minDate: getMinDateFilter(selectedDateRangeType),
        });
    }, [selectedDateRangeType,])

    return (
        <div className="OtherQuestionnaires">
            <Content>
                <Row justify="center" className="filters-container">
                    <Col span={18}>
                        <Row gutter={[15, 30]}>
                            <Col span={8}>
                                <DateRangeDropdown
                                    onChange={(value) => setSelectedDateRangeType(value)}
                                    selected={selectedDateRangeType}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row justify="center">
                    <Col span={18}>
                        <div>
                            <GraphIcon fill={theme['primary-500']} width={24} height={24} />
                            <span className="history-title">{'History'}</span>
                        </div>
                    </Col>
                </Row>

                {data.length > 0 ? (
                    <Row justify="center" gutter={[44, 0]}>
                        <Col span={18}>
                            <PatientOtherQuestionnaireHistory data={data} />
                        </Col>
                    </Row>
                ) : (
                    <NoQuestionnaireData />
                )}
            </Content>
        </div>
    );
});

export default OtherQuestionnaires;
