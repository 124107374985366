import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { SelectCustom } from 'Components/select-custom';
import moment from 'moment';

export interface DateRangeDropdownProps {
    onChange: (value: DateRangeDropdownOption) => void,
    selected: DateRangeDropdownOption;
}

export enum DateRangeDropdownOption {
    CurrentMonth = 'CurrentMonth',
    LastMonth = 'LastMonth',
    Last3Months = 'Last3Months',
    Last6Months = 'Last6Months',
    LastYear = 'LastYear',
}

const DateRangeDropdown: FunctionComponent<DateRangeDropdownProps> = observer(({onChange, selected}) => {
    const optionLabel = (option: string): string => {
        switch (option) {
            case DateRangeDropdownOption.CurrentMonth:
                return 'Current Month';
            case DateRangeDropdownOption.LastMonth:
                return 'Last Month';
            case DateRangeDropdownOption.Last3Months:
                return 'Last 3 Months';
            case DateRangeDropdownOption.Last6Months:
                return 'Last 6 Months';
            case DateRangeDropdownOption.LastYear:
                return 'Last Year';
            default:
                return '';
        }
    }; 

    return (
        <div className="DateRangeDropdown">
            <SelectCustom
                options={Object.keys(DateRangeDropdownOption).map((type) => ({
                    value: type,
                    label: optionLabel(type),
                }))}
                onChange={(option: any) => onChange(option.value)}
                hideSelectedOptions={false}
                selected={[selected]}
                borderless
            />
        </div>
    );
});

export default DateRangeDropdown;

export const getMinDateFilter = (selectedDateRangeType: DateRangeDropdownOption): string => {
    switch (selectedDateRangeType) {
        case DateRangeDropdownOption.Last3Months:
            return moment().add(-3, 'month').format();
        case DateRangeDropdownOption.Last6Months:
            return moment().add(-6, 'month').format();
        case DateRangeDropdownOption.LastMonth:
            return moment().add(-1, 'month').format();
        case DateRangeDropdownOption.CurrentMonth:
            return moment().startOf('month').format();
        case DateRangeDropdownOption.LastYear:
            return moment().add(-12, 'month').format();
    }
};
