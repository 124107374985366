import { RETURN_URL } from 'Models/Constants';
import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import AnonymousLayout from '../../Components/anonymous-layout/anonymous-layout';
import ForgotPassword from './forgot-password/forgot-password';

import Login from './login';
import ResetPassword from './action/reset-password/reset-password';
import DownloadApp from './action/download-app/download-app';
import SetPassword from './action/set-password/set-password';
import ConsolePasswordConfirmed from './action/console-password-confirmed/console-password-confirmed';
import PasswordConfirmed from './action/password-confirmed/password-confirmed';
import PrivacyPolicy from './policies/privacy-policy';
import TermsOfUse from './policies/terms-of-use';

export const AnonymousRoutes: FunctionComponent = () => {
    const location = useLocation();
    return (
        <Switch>
            <Route exact path="/forgot-password">
                <AnonymousLayout>
                    <ForgotPassword />
                </AnonymousLayout>
            </Route>

            <Route exact path="/action/set-password">
                <AnonymousLayout>
                    <SetPassword />
                </AnonymousLayout>
            </Route>

            <Route exact path="/action/reset-password">
                <AnonymousLayout>
                    <ResetPassword />
                </AnonymousLayout>
            </Route>

            <Route exact path="/download-app">
                <AnonymousLayout>
                    <DownloadApp />
                </AnonymousLayout>
            </Route>

            <Route exact path="/console-password-confirmed">
                <AnonymousLayout>
                    <ConsolePasswordConfirmed />
                </AnonymousLayout>
            </Route>

            <Route exact path="/password-confirmed">
                <AnonymousLayout>
                    <PasswordConfirmed />
                </AnonymousLayout>
            </Route>

            <Route exact path="/Privacy_Policy_EN">
                <PrivacyPolicy />
            </Route>
            
            <Route exact path="/Terms_Of_Use_EN">
                <TermsOfUse />
            </Route>

            <Route exact path="/">
                <AnonymousLayout>
                    <Login />
                </AnonymousLayout>
            </Route>

            <Redirect
                to={{
                    pathname: '/',
                    search: `?${RETURN_URL}=${encodeURIComponent(
                        location.pathname + location.search
                    )}`,
                }}
            />
        </Switch>
    );
};

export default AnonymousRoutes;

