import ConfirmationModalContainer from 'Components/confirmation-modal/confirmation-modal-container';
import GlobalLoadingContainer from 'Components/global-loading-container/global-loading-container';
import { ToastContainer } from 'Components/toast-container';
import { useService } from 'Hooks/use-service';
import React, { FunctionComponent, useLayoutEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthenticationService } from 'Services/AuthenticationService';
import Routes from '../Routes';
import './App.less';

const App: FunctionComponent = () => {
    
    const authenticationService = useService(AuthenticationService);
    useLayoutEffect(() => {
        authenticationService.installInterceptors();
    }, [authenticationService]);

    return (
        <BrowserRouter>
            <Routes />
            <ToastContainer />
            <ConfirmationModalContainer />
            <GlobalLoadingContainer />
        </BrowserRouter>
    );
};

export default App;
