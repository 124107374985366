import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import './index.less';

const UnauthorizedPage: FunctionComponent = () => {
    return (
        <div className="UnauthorizedPage">
            <div className="e403">{'403'}</div>
            <div className="H1-bold not-found">{'Unauthorized'}</div>
            <div className="Paragraph text">
                {
                    'You are not authorized to perform this action'
                }
            </div>
            <Button type="primary" className="back-to-site">
                <Link to="/">{'Go back'}</Link>
            </Button>
        </div>
    );
};

export default UnauthorizedPage;
