import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { Layout } from 'antd';
import ListSectionHeader from 'Components/list-section-header/list-section-header';
import { useService, useStores } from 'Hooks';
import ProfileInformationCard from 'Components/profile-information-card/profile-information-card';
import { useState } from 'react';
import './doctor-profile.less'
import { TagColor } from 'Components/tag/tag';
import { PhysicianService } from 'Services/PhysicianService';
import { useHistory, useParams } from 'react-router-dom';
import DropdownButton, { DropdownButtonMenuItem } from 'Components/dropdown-button/dropdown-button';
import { List, Mail, Trash } from 'Components/icons';
import { theme } from 'Style/theme';
import EditDoctorModal from '../edit-doctor-modal';
import { PhysicianDetailsDto } from 'Api/Features/Physicians/Dtos/PhysicianDetailsDto';
import { ResendAccountConfirmationRequestDto } from 'Api/Features/Users/Dtos/ResendAccountConfirmationRequestDto';
import { UserService } from 'Services/UserService';

const { Content } = Layout;

const DoctorProfile: FunctionComponent = () => {
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const { id } = useParams<{ id: string }>();
    const physicianService = useService(PhysicianService);
    const userService = useService(UserService);
    const [data, setData] = useState<PhysicianDetailsDto>(); 
    const [editModalOpen, setEditModalOpen] = useState(false);
    const history = useHistory();

    const fetch = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {

            const response = await physicianService.getPhysician(id);
            if (response) {
                setData(response)
            }
        }
        catch (e) {
            if (!e.treated) {
                toastStore.genericError();
              }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const deleteDoctor = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: null,
                title: 'Are you sure?',
                message: data?.hasPatients ? (
                    <div>
                        <div>
                            {'There are'} <span className="bold">{'patients associated'}</span>{' '}
                            {'to this doctor.'}
                        </div>
                        <div>
                            {'Please ask the doctor to'}
                            <span className="bold">
                                {'transfer his patients before deleting'}
                            </span>{' '}
                            {'the profile'}
                        </div>
                    </div>
                ) : (
                    'This action will permanently delete this profile.'
                ),
                positiveText: data?.hasPatients ? 'Delete anyway' : 'Delete',
                negativeText: 'Cancel',
            }))
        )
            return;

        try {
            globalLoadingStore.addLoading();
            await physicianService.deletePhysician(id);
            toastStore.toast({
                type: 'success',
                message: 'Doctor deleted successfully',
            });
            history.push('/doctors');
        } catch (e) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    
    const resendInvitation = async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const request: ResendAccountConfirmationRequestDto = {
                email: data?.email,
            };
            await userService.resendAccountConfirmation(request);
            toastStore.toast({
                type: 'success',
                message: 'Email sent',
            });
        } finally {
            globalLoadingStore.removeLoading();
        }
    }

    const menuItems = useCallback((): DropdownButtonMenuItem[] => {
        const items: DropdownButtonMenuItem[] = [
            {
                text: 'Edit Profile',
                icon: <List width={20} height={20} fill={theme['primary-color']} />,
                onClick: () => setEditModalOpen(true)
            },
            {
                text: 'Delete Doctor',
                icon: <Trash width={20} height={20} fill={theme['primary-color']} />,
                onClick: () => deleteDoctor()
            },
        ];

        if(!data?.emailConfirmed) {
            items.splice(1, 0, {
                text: 'Resend Invitation',
                icon: <Mail width={20} height={20} fill={theme['primary-color']} />,
                onClick: () => resendInvitation(),
            });
        }

        return items;
    }, [data]);
    return (
        <div className="DoctorProfile">
            <ListSectionHeader action={<DropdownButton menuItems={menuItems()} />} />
            <Content>
                <ProfileInformationCard
                    firstName={data?.firstName}
                    lastName={data?.lastName}
                    email={data?.email}
                    phoneNumber={data?.phoneNumber}
                    isDoctorProfile={true}
                    tagprops={{ text: 'Doctor', color: TagColor.Purple }}
                    pendingInvitation={!data?.emailConfirmed}
                    imageUrl={data?.imageUrl}
                />
            </Content>
            {editModalOpen && (
                <EditDoctorModal
                    visible={editModalOpen}
                    user={data}
                    onComplete={(success: boolean): void => {
                        if (success) fetch();
                        setEditModalOpen(false);
                    }}
                />
            )}
        </div>
    );
};

export default DoctorProfile;
