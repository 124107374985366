// GENERATED FILE - DO NOT MODIFY
import { AdminUserDetailsDto } from 'Api/Features/Users/Dtos/AdminUserDetailsDto';
import { CreateAdminUserRequestDto } from 'Api/Features/Users/Dtos/CreateAdminUserRequestDto';
import { ForgotPasswordRequestDto } from 'Api/Features/Users/Dtos/ForgotPasswordRequestDto';
import { GetAdminUsersRequestDto } from 'Api/Features/Users/Dtos/GetAdminUsersRequestDto';
import { GetAdminUsersResponseDto } from 'Api/Features/Users/Dtos/GetAdminUsersResponseDto';
import { ManagerUserDetailsDto } from 'Api/Features/Users/Dtos/ManagerUserDetailsDto';
import { ResendAccountConfirmationRequestDto } from 'Api/Features/Users/Dtos/ResendAccountConfirmationRequestDto';
import { UpdateAdminUserRequestDto } from 'Api/Features/Users/Dtos/UpdateAdminUserRequestDto';
import { UpdateUserPasswordAnonymousRequestDto } from 'Api/Features/Users/Dtos/UpdateUserPasswordAnonymousRequestDto';
import { UpdateUserPasswordRequestDto } from 'Api/Features/Users/Dtos/UpdateUserPasswordRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class UserProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async resendAccountConfirmation(request: ResendAccountConfirmationRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/resend-account-confirmation",
            null,
            null
        );

        await this.httpClient.post<ResendAccountConfirmationRequestDto | null, void>(uri, request);
    }

    public async forgotPassword(request: ForgotPasswordRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/forgot-password",
            null,
            null
        );

        await this.httpClient.post<ForgotPasswordRequestDto | null, void>(uri, request);
    }

    public async updateUserPasswordAnonymous(request: UpdateUserPasswordAnonymousRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/set-password",
            null,
            null
        );

        await this.httpClient.post<UpdateUserPasswordAnonymousRequestDto | null, void>(uri, request);
    }

    public async updateUserPassword(userId: string, request: UpdateUserPasswordRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/change-password",
            { userId: userId },
            null
        );

        await this.httpClient.post<UpdateUserPasswordRequestDto | null, void>(uri, request);
    }

    public async getManagerUser(userId: string): Promise<ManagerUserDetailsDto | null> {
        const uri = this.buildUri(
            "/manager-users/{userId}",
            { userId: userId },
            null
        );

        const data: ManagerUserDetailsDto | null = await this.httpClient.get<ManagerUserDetailsDto | null>(uri);
        return data;
    }

    public async getAdminUser(userId: string): Promise<AdminUserDetailsDto | null> {
        const uri = this.buildUri(
            "/administrators/{userId}",
            { userId: userId },
            null
        );

        const data: AdminUserDetailsDto | null = await this.httpClient.get<AdminUserDetailsDto | null>(uri);
        return data;
    }

    public async getAdminUsers(request: GetAdminUsersRequestDto | null): Promise<GetAdminUsersResponseDto | null> {
        const uri = this.buildUri(
            "/administrators",
            null,
            { ...request || undefined }
        );

        const data: GetAdminUsersResponseDto | null = await this.httpClient.get<GetAdminUsersResponseDto | null>(uri);
        return data;
    }

    public async createAdminUser(request: CreateAdminUserRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/administrators",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateAdminUserRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateAdminUser(userId: string, request: UpdateAdminUserRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/administrators/{userId}",
            { userId: userId },
            null
        );

        await this.httpClient.patch<UpdateAdminUserRequestDto | null, void>(uri, request);
    }

    public async deleteAdminUser(userId: string): Promise<void> {
        const uri = this.buildUri(
            "/administrators/{userId}",
            { userId: userId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}