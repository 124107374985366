import React, { FunctionComponent, useCallback, useState } from 'react';
import { Button, Divider, Layout, Menu } from 'antd';
import { observer } from 'mobx-react';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './side-navigation.less';
import MPNLogo from 'Assets/Images/logo-white.svg';
import { theme } from 'Style/theme';
import { List, LogOut, Stethoscope, User } from 'Components/icons';
import { useStores } from 'Hooks';
import NoProfilePicture from 'Assets/Images/no-profile.svg';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import ExportDataModal from 'Components/export-data-modal/export-data-modal';

const { Sider } = Layout;

enum RoleType {
    adminRole,
    physicianRole,
}

const SideNavigation: FunctionComponent = observer(() => {
    const history = useHistory();
    const location = useLocation();
    const { authenticationStore, confirmationModalStore, userStore } = useStores();
    const [exportDataModalOpen, setExportDataModalOpen] = useState(false);

    const patientsUrl = /^\/patients\/?.*/;
    const administratorsUrl = /^\/administrators\/?.*/;
    const doctorsUrl = /^\/doctors\/?.*/;

    const userRoleType = useCallback((): RoleType | undefined => {
        if (userStore.userRole === ManagementRoleDto.Physician) return RoleType.physicianRole;
        if (
            userStore.userRole === ManagementRoleDto.Administrator ||
            userStore.userRole === ManagementRoleDto.SuperAdministrator
        )
            return RoleType.adminRole;

        return undefined;
    }, []);

    const onSelectItem = (param: MenuInfo): void => {
        history.push(`${param.key}`);
    };

    const selectedKeys = useCallback((): string[] => {
        const keys: string[] = [];

        if (patientsUrl.test(location.pathname)) {
            keys.push('/patients');
        }

        if (doctorsUrl.test(location.pathname)) {
            keys.push('/doctors');
        }

        if (administratorsUrl.test(location.pathname)) {
            keys.push('/administrators');
        }
        return keys;
    }, [location.pathname]);

    const logout = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: null,
                title: 'Are you sure you want to log out?',
                message: `If you were editing or adding content, your changes won't be saved.`,
                positiveText: 'Yes, log me out',
                negativeText: 'No, keep me logged in',
            }))
        )
            return;
        authenticationStore.clearSession();
        userStore.clearUserInfo();
        history.replace('/');
    };

    return (
        <Sider className="SideNavigation" width={230}>
            <div className="red-top">
                <img id="logo" src={MPNLogo} alt="logo" />
            </div>

            <div className="curved-line">
                <svg viewBox="0 0 500 150">
                    <path
                        d="M-14.21,-10.36 C-47.00,43.91 470.47,51.80 528.05,-9.38 L500.00,0.00 L0.00,0.00 Z"
                        style={{ stroke: 'none', fill: theme['primary-color'] }}
                    ></path>
                </svg>
            </div>

            <div
                className="header-image-container"
                style={{
                    backgroundImage: `url(${userStore.userInfo?.imageUrl ?? NoProfilePicture})`,
                }}
            />

            <div className="user-info">
                {userStore.userInfo?.firstName} {userStore.userInfo?.lastName}
            </div>

            <div className="view-profile" onClick={() => history.push('/profile')}>
                {'View Profile'}
            </div>

            <Divider />

            {userStore.userIsAdminOrSuperAdmin && (
                <>
                    <div className="export-btn-container">
                        <Button type="primary" onClick={() => setExportDataModalOpen(true)}>
                            {'Export Data'}
                        </Button>
                    </div>

                    <Divider />
                </>
            )}

            <Menu
                theme="light"
                mode="inline"
                onSelect={onSelectItem}
                selectedKeys={selectedKeys()}
                inlineIndent={42}
            >
                {userRoleType() === RoleType.adminRole && (
                    <Menu.Item
                        key="/administrators"
                        icon={<User width={16} height={20} fill={theme['primary-color']} />}
                    >
                        {'Administrators'}
                    </Menu.Item>
                )}

                {userRoleType() === RoleType.physicianRole && (
                    <Menu.Item
                        key="/patients"
                        icon={<List width={16} height={20} fill={theme['primary-color']} />}
                    >
                        <Link to="/patients">{'Patients'}</Link>
                    </Menu.Item>
                )}

                {userRoleType() === RoleType.adminRole && (
                    <Menu.Item
                        key="/doctors"
                        icon={<Stethoscope width={16} height={20} fill={theme['primary-color']} />}
                    >
                        {'Doctors'}
                    </Menu.Item>
                )}
            </Menu>

            <Menu className="log-out" mode="inline" onSelect={logout} selectedKeys={['']}>
                <Menu className="log-out" mode="inline" onSelect={logout} selectedKeys={['']}>
                    <Menu.Item key={'logout'} icon={<LogOut fill={theme['primary-500']} />}>
                        {'Log out'}
                    </Menu.Item>
                </Menu>
            </Menu>

            {exportDataModalOpen && (
                <ExportDataModal
                    visible={exportDataModalOpen}
                    onComplete={() => setExportDataModalOpen(false)}
                />
            )}
        </Sider>
    );
});

export default SideNavigation;
