import React, { FunctionComponent, useState } from "react";
import { Button, Row, Col, Form, Input, Alert, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Store } from "antd/es/form/interface";
import { observer } from "mobx-react";
import { ValidatedFormItem } from "Components/validated-form-item";
import { LoginSchema } from "Schemas/LoginSchema";
import { useFormValidation, useService, useStores } from "Hooks";
import { AuthenticationService } from "Services/AuthenticationService";
import { Link } from "react-router-dom";
import "./index.less";
import { Mail, Padlock } from "Components/icons";
import { theme } from "Style/theme";

const Login: FunctionComponent = observer(() => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [errors, validateForm] = useFormValidation(LoginSchema);
  const authenticationService = useService(AuthenticationService);
  const [loginErrorState, setLoginErrorState] = useState<string>();
  const [spinner, setSpinner] = useState(false);
  const { authorizationStore } = useStores();

  //clear history state after consuming so that re-render does not keep state.
  window.history.replaceState(null, "");

  async function submit(values: Store): Promise<void> {
    const valid = await validateForm(values);
    if (!valid) return;
    try {
      setSpinner(true);
      await authenticationService.initAccessToken(
        values.username,
        values.password
      );
    } catch (error) {
      if (error.response?.status === 400)
        setLoginErrorState("This email/password pair does not exist.");
      else setLoginErrorState("An unexpected error occurred.");
    } finally {
      setSpinner(false);
    }
  }

  return (
    <div className="Login">
      {loginErrorState && (
        <Row gutter={[0, 40]}>
          <Col span={12} offset={6}>
            <Alert
              className="login-error"
              message={loginErrorState}
              type="error"
            />
          </Col>
        </Row>
      )}
      {authorizationStore.userCannotAccessSite && (
        <Row gutter={[0, 40]}>
          <Col span={12} offset={6}>
            <Alert
              className="login-error"
              message={"You do not have permission to access this page."}
              type="error"
            />
          </Col>
        </Row>
      )}

      <Row className="heading">
        <Col className="H2-bold">{"Login"}</Col>
      </Row>

      <Row className="heading">
        <Col className="Paragraph">
          {"Enter your details below to continue"}
        </Col>
      </Row>

      <Row>
        <Col span={16} offset={4}>
          <Form
            layout="vertical"
            onFinish={async (values): Promise<void> => {
              submit(values);
            }}
          >
            <ValidatedFormItem
              errors={errors}
              label={"Email"}
              name="username"
              className="email-input"
            >
              <Input
                prefix={<Mail fill={theme["primary-color"]} />}
                maxLength={50}
              />
            </ValidatedFormItem>

            <ValidatedFormItem
              errors={errors}
              label={"Password"}
              name="password"
            >
              <Input
                prefix={<Padlock fill={theme["primary-color"]} />}
                type="password"
                maxLength={50}
              />
            </ValidatedFormItem>

            {spinner && (
              <div className="spinner">
                <Spin indicator={antIcon} size="large" />
              </div>
            )}

            {/* @TODO - Check/ follow how to resolve the findDOMNode warning issue with Ant and Strictmode */}
            {/* See Open issue here: https://github.com/ant-design/ant-design/issues/22493 */}

            <Button type="primary" htmlType="submit" className="login-btn">
              {"Log in"}
            </Button>

            <p className="forgot-password Paragraph-bold">
              <Link to="/forgot-Password">{"Forgot my password"}</Link>
            </p>
          </Form>
        </Col>
      </Row>
    </div>
  );
});

export default Login;
