import React, { FunctionComponent, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import './patient-mpn10-history.less';
import moment from 'moment';
import PatientMPN10HistoryCollapse from 'Components/patient-mpn10-history-collapse/patient-mpn10-history-collapse';
import SymptomExplanationModal from 'Components/symptom-explanation-modal/symptom-explanation-modal';
import { useEffect } from 'react';
import { SurveyResponseDto } from 'Api/Features/SurveyResponses/Dtos/SurveyResponseDto';
import { MPNSymptom, SymptomDropdownExtendedMPNSymptomEnum } from 'Models/Mpn10Question/Mpn10QuestionMap';
import { groupByMonth } from 'Utils/SurveyResponseDtoUtils';

interface PatientMPN10HistoryProps {
    data?: SurveyResponseDto[];
    selectedSymptom: SymptomDropdownExtendedMPNSymptomEnum;
}

interface SymptomModalState {
    visible: boolean;
    symptom: MPNSymptom;
}

interface PatientHistoryCollapseExpandState {
    [id: string]: boolean;
}

const PatientMPN10History: FunctionComponent<PatientMPN10HistoryProps> = observer(({ data, selectedSymptom }) => {
    const [expandedStates, setExpandedStates] = useState<PatientHistoryCollapseExpandState>();
    const [symptomModalState, setSymptomModalState] = useState<SymptomModalState>({
        visible: false,
        symptom: MPNSymptom.Fatigue,
    });

    const orderedData = useCallback((): SurveyResponseDto[] => {
        if (data && data?.length > 0) {
            const orderedByDate = data
                .sort((a, b) => (moment(b.date) as any) - (moment(a.date) as any));
            return orderedByDate;
        }
        else {
            return [];
        }
    }, [data])

    const handleExpandClick = (id: string, isExpanded: boolean): void => {
        const oldState = {...expandedStates};
        oldState[id] = isExpanded;
        setExpandedStates(oldState);
    };

    useEffect(() => {
        const expandedState: PatientHistoryCollapseExpandState = {};
        orderedData().map(
            (data, i) =>
                (expandedState[data.id!] =
                    selectedSymptom === SymptomDropdownExtendedMPNSymptomEnum.TotalScore && i === 0)
        );
        setExpandedStates(expandedState);
    }, [data, selectedSymptom]);

    return (
        <div className="PatientMPN10History">
            <div className="history-container">
                {expandedStates &&
                    groupByMonth(orderedData()).map((group) => (
                        <div className='month-container' key={group.groupString}>
                            <div className="month-title">{group.groupString}</div>

                            {group.surveys.map((item) => (
                                <PatientMPN10HistoryCollapse
                                    key={`${item.id} + ${item.date}`}
                                    data={item}
                                    onSymptomTooltipClick={(symptom: MPNSymptom) =>
                                        setSymptomModalState({ visible: true, symptom: symptom })
                                    }
                                    isExpanded={item.id ? expandedStates[item.id!] : false}
                                    onExpandClick={(id: string, isExpanded: boolean) =>
                                        handleExpandClick(id, isExpanded)
                                    }
                                    selectedSymptom={selectedSymptom}
                                    isExpandable={
                                        selectedSymptom ===
                                        SymptomDropdownExtendedMPNSymptomEnum.TotalScore
                                    }
                                />
                            ))}
                        </div>
                    ))}
            </div>

            {symptomModalState && (
                <SymptomExplanationModal
                    visible={symptomModalState.visible}
                    title={symptomModalState.symptom}
                    onClose={() =>
                        setSymptomModalState({ visible: false, symptom: MPNSymptom.Fatigue })
                    }
                />
            )}
        </div>
    );
});

export default React.memo(PatientMPN10History);
