import React, { FunctionComponent } from "react";
import { Row, Col, Button } from "antd";
import { observer } from "mobx-react";
import './console-password-confirmed.less';
import { useHistory } from "react-router-dom";

const ConsolePasswordConfirmed: FunctionComponent = observer(() => {
  const history = useHistory();
  return (
      <div className="ConsolePasswordConfirmed">
          <Row className="heading">
              <Col className="H2-bold">{"It's Updated!"}</Col>
          </Row>

          <Row className="heading">
              <Col className="Paragraph">{'Your password has been successfully updated.'}</Col>
          </Row>

          <Button className="login-btn" type='primary' onClick={() => history.push('/')}>
              {'Log in'}
          </Button>
      </div>
  );
});

export default ConsolePasswordConfirmed;
