export const MOMENT_YEAR_FORMAT = 'YYYY';
export const DEFAULT_PAGE_SIZE = 10;
export const DEBOUNCE_DELAY = 500;
export const ALL_TREATMENTS = 'All treatments';
export const MOMENT_DAY_MONTH_YEAR_STRING_FORMAT = 'DD/MM/YYYY';
export const MOMENT_WRITTEN_MONTH_DAY_YEAR_FORMAT = 'MMMM Do YYYY';
export const MOMENT_DAY_MONTH_FORMAT = 'D/M';
export const MOMENT_MONTH_DAY_YEAR_FORMAT = 'MMMM DD YYYY';
export const MOMENT_TWELVE_HOUR_TIME = 'HH:mm a';
export const PHONE_REGEX =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const ALL_ROLES = 'All roles';
export const RETURN_URL = 'returnUrl';
export const API_DATE_TIME_REQUEST_FORMAT = 'YYYY-MM-DDTHH:MM:SS-0:00';
export const MOMENT_MONTH_YEAR_DISPLAY_FORMAT = 'MMMM YYYY';
export const MPN10_SURVEY_ID = '06083bd9-aa47-44f9-a6a7-5623ae3e82c1';
export const MOMENT_MONTH_DISPLAY_FORMAT = 'MMMM';
export const MOMENT_SHORT_MONTH_DISPLAY_FORMAT = 'MMM';
export const APP_STORE_LINK = 'https://apps.apple.com/app/id1617694721';
export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=ca.mpngenie';