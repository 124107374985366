import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Content } from 'antd/lib/layout/layout';
import PatientStatistics from 'Components/patient-statistics/patient-statistics';
import PatientMPN10History from 'Components/patient-mpn10-history/patient-mpn10-history';
import { Col, Row } from 'antd';
import { useService, useStores } from 'Hooks';
import { SurveyResponseService } from 'Services/SurveyResponseService';
import { useState } from 'react';
import { SurveyResponseDto } from 'Api/Features/SurveyResponses/Dtos/SurveyResponseDto';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { GetSurveyResponsesRequestDto } from 'Api/Features/SurveyResponses/Dtos/GetSurveyResponsesRequestDto';
import { useParams } from 'react-router-dom';
import './mpn10.less';
import NoQuestionnaireData from 'Components/no-questionnaire-data';
import { GraphIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import DateRangeDropdown, { DateRangeDropdownOption, getMinDateFilter } from 'Components/date-range-dropdown';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import { MPN10_SURVEY_ID } from 'Models/Constants';
import { SymptomDropdownExtendedMPNSymptomEnum } from 'Models/Mpn10Question/Mpn10QuestionMap';

const Mpn10: FunctionComponent = observer(() => {
    const { globalLoadingStore, toastStore } = useStores();
    const { id } = useParams<{ id: string }>();
    const surveyResponseService = useService(SurveyResponseService);
    const [data, setData] = useState<SurveyResponseDto[]>([]);
    
    const [selectedDateRangeType, setSelectedDateRangeType] = useState<DateRangeDropdownOption>(
        DateRangeDropdownOption.Last3Months
    );
    const [selectedSymptom, setSelectedSymptom] =
        useState<SymptomDropdownExtendedMPNSymptomEnum>(SymptomDropdownExtendedMPNSymptomEnum.TotalScore);

    const fetch = useCallback(async (minDate?: string ) => {
        try {
            globalLoadingStore.addLoading();
            let currentPage = 0;
            let fetchedAll = false;
            const result: SurveyResponseDto[] = [];

            while (!fetchedAll) {
                const request: GetSurveyResponsesRequestDto = {
                    patientIds: [id],
                    surveyIds: [MPN10_SURVEY_ID],
                    page: currentPage,
                    pageSize: 50,
                    minDate
                };
                const [response, totalItemCount] = await surveyResponseService.getSurveyResponses(request);
                result.push(...response)
                currentPage++;
                fetchedAll = result.length === totalItemCount;
            }
            setData(result);
        } catch (e) {
            if (!e.treated) {
                toastStore.serverError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, []);

    useEffect(() => {
        fetch(getMinDateFilter(selectedDateRangeType));
    }, [fetch, selectedDateRangeType]);

    const symptomOptions = useCallback((): SelectCustomOption[] => {
        const options = Object.keys(SymptomDropdownExtendedMPNSymptomEnum).map(
            (symptom) =>
                ({
                    value: SymptomDropdownExtendedMPNSymptomEnum[symptom],
                    label: SymptomDropdownExtendedMPNSymptomEnum[symptom],
                } as SelectCustomOption)
        );

        return options;
    }, []);

    return (
        <div className="Mpn10">
            <Content>
                <Row className="filters-container">
                    <Col span={24}>
                        <Row gutter={[15, 30]} justify="center">
                            <Col span={8}>
                                <DateRangeDropdown
                                    onChange={(value) => setSelectedDateRangeType(value)}
                                    selected={selectedDateRangeType}
                                />
                            </Col>

                            <Col span={8}>
                                <SelectCustom
                                    options={symptomOptions()}
                                    onChange={(value: any): void => {
                                        setSelectedSymptom(value.value);
                                    }}
                                    hideSelectedOptions={false}
                                    selected={selectedSymptom ? [selectedSymptom] : []}
                                    borderless
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <div>
                            <GraphIcon fill={theme['primary-500']} width={24} height={24} />
                            <span className="stats-title">{'Statistics'}</span>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="history-title-container">
                            <GraphIcon fill={theme['primary-500']} width={24} height={24} />
                            <span className="history-title">{'History'}</span>
                        </div>
                    </Col>
                </Row>

                {data.length > 0 ? (
                    <Row gutter={[44, 0]}>
                        <Col span={12} className="stat-col">
                            <PatientStatistics data={data} selectedSymptom={selectedSymptom} selectedDateRange={selectedDateRangeType}/>
                        </Col>
                        <Col span={12}>
                            <PatientMPN10History data={data} selectedSymptom={selectedSymptom} />
                        </Col>
                    </Row>
                ) : (
                    <NoQuestionnaireData />
                )}
            </Content>
        </div>
    );
});

export default Mpn10;
