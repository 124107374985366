import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { Layout } from 'antd';
import ListSectionHeader from 'Components/list-section-header/list-section-header';
import { useService, useStores } from 'Hooks';
import ProfileInformationCard from 'Components/profile-information-card/profile-information-card';
import { useState } from 'react';
import './administrator-profile.less'
import { TagColor } from 'Components/tag/tag';
import { useHistory, useParams } from 'react-router-dom';
import { UserService } from 'Services/UserService';
import { AdminUserDetailsDto } from 'Api/Features/Users/Dtos/AdminUserDetailsDto';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import EditAdministratorModal from '../edit-administrator-modal';
import DropdownButton, { DropdownButtonMenuItem } from 'Components/dropdown-button/dropdown-button';
import { List, Mail, Trash } from 'Components/icons';
import { theme } from 'Style/theme';
import { ResendAccountConfirmationRequestDto } from 'Api/Features/Users/Dtos/ResendAccountConfirmationRequestDto';

const { Content } = Layout;

const AdministratorProfile: FunctionComponent = () => {
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const { id } = useParams<{ id: string }>();
    const userService = useService(UserService);
    const [data, setData] = useState<AdminUserDetailsDto>();
    const [administratorModalOpen, setAdministratorModalOpen] = useState(false);
    const history = useHistory();

    const fetch = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            const response = await userService.getAdminUser(id);
            if (response) {
                setData(response);
            }
        }
        catch (e) {
            if (!e.treated) {
                toastStore.genericError();
              }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const deleteAdmin = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: null,
                title: 'Are you sure? ',
                message: `This action will permanently delete this profile`,
                positiveText: 'Yes, delete profile',
                negativeText: 'Cancel',
            }))
        )
            return;

        await userService.deleteAdminUser(id);
        history.goBack();
    };

    const resendInvitation = async (): Promise<void> => {
        globalLoadingStore.addLoading();
        try {
            // call api
            const request: ResendAccountConfirmationRequestDto = {
                email: data?.email,
            };
            await userService.resendAccountConfirmation(request);
            toastStore.toast({
                type: 'success',
                message: 'Email sent',
            });
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const menuItems = useCallback((): DropdownButtonMenuItem[] => {
        const items: DropdownButtonMenuItem[] = [
            {
                text: 'Edit Profile',
                icon: <List width={20} height={20} fill={theme['primary-color']} />,
                onClick: () => setAdministratorModalOpen(true),
            },
            {
                text: 'Delete Profile',
                icon: <Trash width={20} height={20} fill={theme['primary-color']} />,
                onClick: () => deleteAdmin(),
            },
        ];

        if(!data?.emailConfirmed) {
            items.splice(1, 0, {
                text: 'Resend Invitation',
                icon: <Mail width={20} height={20} fill={theme['primary-color']} />,
                onClick: () => resendInvitation(),
            });
        }

        return items;
    }, [data]);

    return (
        <div className="AdministratorProfile">
            <ListSectionHeader
                action={
                    <DropdownButton menuItems={menuItems()}/>
                }
            />

            <Content>
                <ProfileInformationCard
                    firstName={data?.firstName}
                    lastName={data?.lastName}
                    email={data?.email}
                    tagprops={
                        data?.managementRoles?.[0]?.name === ManagementRoleDto.Administrator
                            ? { text: 'Administrator', color: TagColor.Purple }
                            : { text: 'Super Administrator', color: TagColor.Green }
                    }
                    pendingInvitation={!data?.emailConfirmed}
                    imageUrl={data?.imageUrl}
                />
            </Content>

            {administratorModalOpen && (
                <EditAdministratorModal
                    visible={administratorModalOpen}
                    onComplete={(success: boolean): void => {
                        if (success) fetch();
                        setAdministratorModalOpen(false);
                    }}
                    user={data}
                />
            )}
        </div>
    );
};

export default AdministratorProfile;
