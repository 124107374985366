import React, { FunctionComponent } from "react";
import moment from "moment";
import "./last-mpn-answer-badge.less";
import { MOMENT_DAY_MONTH_YEAR_STRING_FORMAT } from "Models/Constants";

interface LastMPNAnswerBadgeProps {
  lastAnswerDate?: string;
  displayDate?: boolean
}

const LastMPNAnswerBadge: FunctionComponent<LastMPNAnswerBadgeProps> = ({
  lastAnswerDate,
  displayDate
}) => {
  const badgeDetails = (): {
      value: string;
      className: string;
  } => {
      const currentMoment = moment();
      const lastAnswerMoment = moment(lastAnswerDate);
      const timeElapsedInDays = currentMoment.diff(lastAnswerMoment, 'days');
      const timeElapsedInWeeks = currentMoment.diff(lastAnswerMoment, 'weeks');
      const timeElapsedInMonths = currentMoment.diff(lastAnswerMoment, 'months');
      const timeElapsedInYears = currentMoment.diff(lastAnswerMoment, 'years');

      let result = { value: '', className: '' };

      if (lastAnswerDate === undefined) {
          result = {
              value: 'No Data Yet',
              className: 'grey',
          };
      } else if (timeElapsedInYears > 0) {
          result = {
              value: `${timeElapsedInYears} year${timeElapsedInDays > 1 ? 's' : ''} ago`,
              className: 'red',
          };
      } else if (timeElapsedInMonths > 0) {
          result = {
              value: `${timeElapsedInMonths} month${timeElapsedInMonths > 1 ? 's' : ''} ago`,
              className: timeElapsedInMonths > 2 ? 'orange' : 'yellow',
          };
      } else if (timeElapsedInWeeks > 0) {
          result = {
              value: `${timeElapsedInWeeks} week${timeElapsedInWeeks > 1 ? 's' : ''} ago`,
              className: 'green',
          };
      } else {
          result = {
              value: `${timeElapsedInDays} day${timeElapsedInDays > 1 ? 's' : ''} ago`,
              className: 'green',
          };
      }

      if (displayDate && lastAnswerDate !== undefined) {
          result = {
              ...result,
              value: `Last MPN 10 answer on ${moment(lastAnswerDate).format(
                  MOMENT_DAY_MONTH_YEAR_STRING_FORMAT
              )}`,
          };
      }

      return result;
  };

  return (
    <div className="LastMPNAnswerBadge">
      <div className={`inner-container ${badgeDetails().className}`}>
        <div className="circle" />
        <div className="text">{`${badgeDetails().value} `}</div>
      </div>
    </div>
  );
};

export default LastMPNAnswerBadge;
