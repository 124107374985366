import { DiagnosisDto } from "Api/Features/Patients/Dtos/DiagnosisDto"

export const DiagnosisToString = (diagnosis: DiagnosisDto): string => {
    switch (diagnosis) {
        case DiagnosisDto.EssentialThrombocythemia:
            return 'Essential Thrombocythemia';
        case DiagnosisDto.Myelofibrosis: 
            return 'Myelofibrosis';
        case DiagnosisDto.OtherNonClassified:
            return 'Other/Non Classified';
        case DiagnosisDto.PolycythemiaVera:
            return 'Polycythemia vera'
    }
} 