import React, { FunctionComponent } from 'react';
import MPNLogo from 'Assets/Images/logo-white.svg';
import MPNLogoRed from 'Assets/Images/logo-red.svg';
import { theme } from 'Style/theme';
import './policies.less';
import moment from 'moment';
import { MOMENT_YEAR_FORMAT } from 'Models/Constants';

const TermsOfUse: FunctionComponent = () => {
    return (
        <div className="PrivacyPolicy">
            <div className="header-container">
                <div className="logo-container">
                    <img className="logo" src={MPNLogo} alt="logo" />
                </div>

                <div className="curved-line">
                    <svg viewBox="0 0 500 150">
                        <path
                            d="M-14.21,-10.36 C-47.00,43.91 470.47,51.80 528.05,-9.38 L500.00,0.00 L0.00,0.00 Z"
                            style={{ stroke: 'none', fill: theme['primary-color'] }}
                        ></path>
                    </svg>
                </div>
            </div>

            <div className="box-container">
                <div className="box Shadow-MPN">
                    <div className="H2-bold">Terms of Use</div>
                    <div className="Paragraph">
                        MPNGenie is a mobile application through which individuals suffering from
                        the MPN blood cancers who registered to use the application “users” may
                        provide historical and ongoing information related to their or with the
                        consent of another’s user’s may provide historical and ongoing information
                        related to their or with the consent of another users diagnosis, symptoms,
                        treatment decisions, and daily activities and quality of life collectively,
                        user data “to the Canadian MPN Research Foundation” “CMPNRF”.
                    </div>
                    <div className="Paragraph">
                        Users have access to their information in the application and may choose to
                        give access to their healthcare provider. Users will also have access to
                        educational information about MPN blood cancers collectively with user data,
                        “content”. To register a use the application, you must agree to these terms
                        of use. These terms of use constitute an agreement between you as a user
                        “YOU” and CMPNRF “CMPNRF”, “us”, “we”, “our”. Please read these terms of use
                        and our privacy policy carefully because they will apply to your use of
                        MPNGenie and content. By using MPNGenie you are indicating your
                        understanding of and agreement to be bound by these terms of use and having
                        your personal information, collectively with personal health information
                        “PI” managed in accordance with our privacy policy, and acknowledgement that
                        you have the right, authority, and legal capacity to enter into these terms
                        of use with respect to yourself and any minor user on whose behalf you are
                        illegally authorized to act. If you do not agree to be bound by these terms
                        of use and to have your PI managed in accordance with our privacy policy,
                        you must not use MPNGenie. You also need to be at of the age of majority and
                        otherwise capable of entering into a binding contract under the applicable
                        law in your province. If you are an individual under the age of majority in
                        the jurisdiction in which you live “minor”, you may only use MPNGenie with
                        your parent or guardians’ permission, if your parent or guardian has read
                        and accepted these terms of use on your behalf and has agreed to take full
                        responsibility for your compliance with these terms of use.
                    </div>
                    <div className="Paragraph">
                        We may modify these terms of use at anytime. The current version of these
                        terms of use will be available each time you access MPNGenie. You agree to
                        be bound by any changes to these terms of use when you continue to use
                        MPNGenie after those changes are posted.
                    </div>

                    <div className="H2-bold">Privacy Policy</div>
                    <div className="Paragraph">
                        The privacy policy is hereby incorporated into these terms of use by
                        reference. Please read the privacy policy carefully for information
                        regarding our collection, use and disclosure of any PI you provide to us
                        including your user data.
                    </div>
                    <div className="Paragraph">
                        Registration fees to access MPNGenie are not required to register for an
                        account. To register, you require a valid email address and a password
                        collectively, called your “credentials”. You will be asked to create or be
                        given a password; you will need to input your credentials every time you
                        want to use MPNGenie. You will treat your credentials as confidential
                        information, which means that you will not disclose them to any person or
                        entity and will take reasonable steps to keep them from being discovered by
                        others either intentionally or in add certainly. Your understanding and
                        agree to that your account and credentials are personal to you, and you
                        agree not to provide any other person with access to MPNGenie or any part of
                        MPNGenie using your credentials. You agree to notify us immediately by email
                        as indicated at the bottom of these terms of use of any unauthorized access
                        to or use of authorize access to or use of your credentials and any other
                        breach of security involving your account. You are responsible for any
                        misuse of or unauthorized access to MPNGenie or content using your
                        credentials.
                    </div>
                    <div className="Paragraph">
                        At present, there are no fees required to use MPNGenie. We will give you
                        reasonable notice and the opportunity to close your account if we intend to
                        introduce fees.
                    </div>

                    <div className="H2-bold">Intellectual property, license to use MPNGenie.</div>
                    <div className="Paragraph">
                        Except as otherwise provided in these terms of use, as between you and us,
                        and MPNGenie including any and all content, visual interfaces, information,
                        design, computer code, software, trademarks, service marks, trade names,
                        text, graphics, trademarks, logos, and other material provided through
                        MPNGenie is our property. MPNGenie is protected by intellectual property
                        rights under Canadian laws. You have no right in or to MPNGenie or content
                        except your right in regard to your user data and will not use MPNGenie or
                        content except your user data except as provided or permitted under these
                        terms of use. For clarity, you are not permitted to transfer or assign the
                        license granted to you to use MPNGenie and the content to others. Your
                        access to MPNGenie will be suspended or terminated as further described
                        below if you do not comply with the terms of use.
                    </div>
                    <div className="Paragraph">
                        User data you may choose to input or the user data of your minor children,
                        and adults over the age of majority in the jurisdiction in which they live
                        with their explicit consent. By imputing or accessing the user data of
                        someone other than you, you are representing to CMPNRF and the CMPNRF is
                        relying on your representation that you have the consent of the individual
                        to whom the user data belongs or otherwise legally authorized to collect,
                        use, and disclose that user data. You are responsible for the accuracy of
                        all information that you input into MPNGenie as well as obtaining consent
                        from any individual whose information you choose to input into MPNGenie
                        except minor children if you are authorized to make decisions regarding
                        their PI without their consent. By imputing user data, you agree an
                        weren&apos;t that you have the necessary rights to do so including to
                        disclose to us. You understand and agree that you and not us are fully
                        responsible and liable for any user data you input into MPNGenie to us and
                        any third party for the information and its accuracy. We are not responsible
                        or liable to you or any third party for the content or accuracy of any user
                        data submitted by you for another&apos;s use.
                    </div>

                    <div className="H2-bold">Intellectual property, license to use MPNGenie.</div>
                    <div className="Paragraph">
                        In addition to the other restrictions outlined in these terms of use, you
                        agree and word that you will not:
                        <ol>
                            <li>
                                Input into MPNGenie need any false, inaccurate, or misleading
                                information.
                            </li>
                            <li>
                                Access, use, or disclose someone else’s user’s data without consent
                                except where otherwise authorized in connection with your minor
                                child.
                            </li>
                            <li>
                                Reproduce or attempt to reproduce MPNGenie, or modify, create,
                                translate, or adopt any derivative works of MPNGenie or attempt to
                                do any of the foregoing.
                            </li>
                            <li>
                                Use MPNGenie in violation of these terms of use and any laws,
                                including without limitation, laws governing intellectual property,
                                data protection and privacy, and import or export control.
                            </li>
                            <li>
                                Compromise the security of MPNGenie, which includes circumventing,
                                removing, damaging, disabling or interfering with MPNGenie or any
                                technological feature or measures in or of MPNGenie, or attempt to
                                access areas or features of MPNGenie that you are not authorized to
                                access if any, including using tempting to use another user&apos;s
                                credentials.
                            </li>
                            <li>
                                Use NPN genie in any manner that could interfere with, disrupt,
                                negatively affect, or inhibit use of MPN Genie or other users from
                                fully enjoying MPNGenie, by any means, including uploading or
                                otherwise just disseminate if viruses, worms or other malicious
                                code.
                            </li>
                            <li>
                                Use any robot, spider, crawler, scraper, or other automated means to
                                interface to access MPNGenie, to extract data or to bypass or access
                                measures we may use to prevent or restrict access to MPNGenie.
                            </li>
                            <li>
                                Decompile, disassemble, reverse engineer, or otherwise attempt to
                                derive the source code of MPNGenie or bypass or circumvent measures
                                employed to prevent or limit access to any area, content, or code of
                                MPNGenie.
                            </li>
                            <li>
                                Impersonate any person or entity or otherwise misrepresent your
                                affiliation with a person or entity sound.
                            </li>
                            <li>
                                Engage in any harassing, intimidating, predatory or stocking conduct
                                through the use of or in connection with your use of MPNGenie.
                            </li>
                            <li>
                                Remove or alter any trademark, logo, copyright or other proprietary
                                notices, legends, labels in or an MP and Genie or used in connection
                                with MPNGenie including any content.
                            </li>
                            <li>
                                Distribute, encumber, by, cell, rent, lease, sublease, transfer,
                                published, disclose, or otherwise offer in exchange for any
                                compensation,MPNGenieor access to your account or credentials.
                            </li>
                            <li>
                                Develop any third-party applications that interface with MPNGenie.
                            </li>
                            <li>
                                Use or otherwise encourage or promote the use of MPNGenie for any
                                purpose that is illegal, unauthorized, beyond the scope of its
                                intended use, or otherwise prohibited in these terms of use,
                                including any content that is defamatory, obscene, pornographic,
                                vulgar, offence, promotes discrimination, bigotry, racism, hatred,
                                harassment, or harm against any individual or group, is violent or
                                threatening or promotes violence or actions that are threatening, or
                                promotes illegal or harmful activities or substances; and
                            </li>
                            <li>
                                Gives the impression that your use of MPNGenie is endorsed by us or
                                any other person or entity.
                            </li>
                            <li>
                                Use MPNGenie as the sole means of communication between you and any
                                of your health care providers.
                            </li>
                        </ol>
                    </div>

                    <div className="H2-bold">Termination and Suspension</div>
                    <div className="Paragraph">
                        We reserve the right without liability, but are not obligated, to remove or
                        disable access to MPNGenie or any user data at anytime and without notice,
                        including but not limited to, if we, in our sole discretion, consider your
                        use of MPNGenie or user data to be objectionable or otherwise in violation
                        of these terms of use. We have the right to investigate violations of these
                        terms of use or conduct that affects MPNGenie need, content or users.
                    </div>
                    <div className="Paragraph">
                        In addition, we reserve the right to do any of the following at anytime for
                        any reason, in our sole discretion, without prior notice or liability:
                    </div>
                    <div className="Paragraph">
                        <ul>
                            <li>
                                restrict, suspend, or terminate these terms of use and your access
                                to all or any part of MPNGenie
                            </li>
                            <li>change, suspend or discontinue any or all part of MPNGenie.</li>
                            <li>remove or reuse to post an MP and Genie any user data; And</li>
                            <li>
                                take appropriate legal action, including without limitation,
                                referral to law enforcement, a regulatory or other governmental
                                authority or harmed person or entity for any illegal or unauthorized
                                use of MPNGenie.
                            </li>
                        </ul>
                    </div>

                    <div className="Paragraph">
                        MPNGENIE is a registered trademark and is the sole property of the Canadian MPN Research Foundation
                    </div>
                </div>
            </div>

            <div className="footer">
                <div className="copyright">{`© ${moment().format(
                    MOMENT_YEAR_FORMAT
                )} MPNGENIE Inc. All rights reserved`}</div>
                <img className="logo" src={MPNLogoRed} alt="logo" />
            </div>
        </div>
    );
};

export default TermsOfUse;
