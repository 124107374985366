import React, { FunctionComponent, useCallback } from 'react';
import { ChevronDown, ChevronUp, QuestionMarkCircle } from 'Components/icons';
import { observer } from 'mobx-react';
import DonutChart from 'Components/donut-chart/donut-chart';
import { theme } from 'Style/theme';
import { Collapse } from 'antd';
import './patient-mpn10-history-collapse.less';
import { ReactNode } from 'react';
import { MOMENT_MONTH_DAY_YEAR_FORMAT, MOMENT_TWELVE_HOUR_TIME } from 'Models/Constants';
import { SurveyResponseDto } from 'Api/Features/SurveyResponses/Dtos/SurveyResponseDto';
import moment from 'moment';
import {
    Mpn10QuestionMap,
    Mpn10StringQuestionMap,
    MPNSymptom,
    SymptomDropdownExtendedMPNSymptomEnum,
} from 'Models/Mpn10Question/Mpn10QuestionMap';

interface PatientMPN10HistoryCollapseProps {
    data: SurveyResponseDto;
    onSymptomTooltipClick: (symptom: MPNSymptom) => void;
    isExpanded: boolean;
    onExpandClick: (id: string, isExpanded: boolean) => void;
    selectedSymptom: SymptomDropdownExtendedMPNSymptomEnum;
    isExpandable: boolean;
}

const { Panel } = Collapse;

const PatientMPN10HistoryCollapse: FunctionComponent<PatientMPN10HistoryCollapseProps> = observer(
    ({
        data,
        onSymptomTooltipClick,
        isExpanded,
        onExpandClick,
        selectedSymptom,
        isExpandable,
    }) => {

        const header = (): ReactNode => (
            <div className="header">
                <div className="date">
                    {moment.parseZone(data.date).format(MOMENT_MONTH_DAY_YEAR_FORMAT)}
                </div>
                <div className="time">
                    {moment(data.submissionDate).format(MOMENT_TWELVE_HOUR_TIME)}
                </div>
            </div>
        );

        const isSpecificSymptom = useCallback(() => {
            return selectedSymptom !== SymptomDropdownExtendedMPNSymptomEnum.TotalScore;
        }, [selectedSymptom]);

        const specificResponse = useCallback(() => {
            return data.questionResponses?.find(
                (response) => response?.questionId === Mpn10StringQuestionMap[selectedSymptom]
            );
        }, [selectedSymptom, data]);

        const getScore = useCallback((): number => {
            return isSpecificSymptom() ? specificResponse()?.numericValue ?? 0 : data.score ?? 0;
        }, [selectedSymptom, data]);

        const getDonutData = useCallback(() => {
            const score = getScore();

            const emptyPortionScore = isSpecificSymptom() ? 10 - score : 100 - score;

            return [
                { value: score, color: theme['primary-500'] },
                {
                    value: emptyPortionScore,
                    color: theme['grayscale-200'],
                },
            ];
        }, [selectedSymptom, data]);

        return (
            <div className="PatientMPN10HistoryCollapse">
                <Collapse
                    expandIconPosition={'right'}
                    expandIcon={(props) =>
                        isExpandable ? (
                            props.isActive ? (
                                <ChevronUp width={22} height={22} fill={theme['primary-color']} />
                            ) : (
                                <ChevronDown width={22} height={22} fill={theme['primary-color']} />
                            )
                        ) : undefined
                    }
                    onChange={() => {
                        onExpandClick(data.id!, !isExpanded);
                    }}
                    activeKey={isExpanded ? 1 : undefined}
                >
                    <Panel
                        key={1}
                        collapsible={isExpandable ? undefined : 'disabled'}
                        header={header()}
                        extra={
                            isExpanded ? (
                                <span className="close">{'Close'}</span>
                            ) : (
                                <div className="donut-container">
                                    <div className="donut">
                                        <DonutChart data={getDonutData()} animate={false} />
                                    </div>

                                    <span className="value">{getScore()}</span>
                                </div>
                            )
                        }
                    >
                        <div className="open-panel-content">
                            <div className="total-score-container">
                                <div className="total-score">{'Total Score'}</div>
                                <div className="donut-container">
                                    <div className="donut">
                                        <DonutChart
                                            data={[
                                                { value: data.score!, color: theme.white },
                                                //Take remaining of max value 100
                                                {
                                                    value: 100 - data.score!,
                                                    color: theme['primary-700'],
                                                },
                                            ]}
                                            animate={false}
                                        />
                                    </div>

                                    <span className="value">{data.score!}</span>
                                </div>
                            </div>

                            <div className="individual-scores-container Shadow-MPN">
                                {Object.keys(MPNSymptom).map((symptom) => (
                                    <div key={symptom} className="individual-score">
                                        <div className="name">{MPNSymptom[symptom]}</div>
                                        <div
                                            className="tooltip"
                                            onClick={() =>
                                                onSymptomTooltipClick(MPNSymptom[symptom])
                                            }
                                        >
                                            {<QuestionMarkCircle fill={theme['primary-color']} />}
                                        </div>
                                        <div className="score">
                                            {data.questionResponses?.find(
                                                (response) =>
                                                    response?.questionId ===
                                                    Mpn10QuestionMap[symptom]
                                            )?.numericValue ?? 0}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Panel>
                </Collapse>
            </div>
        );
    }
);

export default React.memo(PatientMPN10HistoryCollapse);
