import React, { FunctionComponent, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useFormValidation, useService, useStores } from "Hooks";
import { ValidatedFormItem } from "Components/validated-form-item";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Row, Col, Form, Input, Spin } from "antd";
import { ResetPasswordEmailSchema } from "Schemas/ResetPasswordSchema";
import "./forgot-password.less";
import { ChevronLeft, Mail } from "Components/icons";
import { theme } from "Style/theme";
import { UserService } from "Services/UserService";
import { ForgotPasswordRequestDto } from "Api/Features/Users/Dtos/ForgotPasswordRequestDto";

const ForgotPassword: FunctionComponent = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [errors, validateForm] = useFormValidation(ResetPasswordEmailSchema);
  const userService = useService(UserService);
  const [redirectPath, setRedirect] = useState<string>();
  const [spinner, setSpinner] = useState(false);
  const history = useHistory();
  const { toastStore } = useStores();

  if (redirectPath) {
    return (
      <Redirect
        to={{
          pathname: redirectPath,
          state: { resetPasswordEmail: true },
        }}
      />
    );
  }
  return (
    <div className="ForgotPassword">
      <Row>
        <Col>
          <div
            className="Paragraph-bold back"
            onClick={(): void => history.goBack()}
          >
            <ChevronLeft fill={theme["primary-color"]} />
            <span className="text">{"Back to login"}</span>
          </div>
        </Col>
      </Row>
      <Row className="heading">
        <Col className="H2-bold">{"Reset Your Password"}</Col>
      </Row>

      <Row className="heading">
        <Col className="Paragraph">
          {"Enter the email address associated with your account"}
        </Col>
      </Row>
      <Form
        layout="vertical"
        onFinish={async (values): Promise<void> => {
          const model: ForgotPasswordRequestDto = {
            email: values.email
          }
          const valid = await validateForm(model);
          if (!valid) return;
          try {
            setSpinner(true);
            await userService.forgotPassword(model);
          } finally {
            setSpinner(false);
            toastStore.toast({
                type: 'success',
                message: 'Reset password email sent'
            })
            setRedirect("/");
          }
        }}
      >
        <Row gutter={[0, 50]}>
          <Col span={16} offset={4}>
            <ValidatedFormItem
              errors={errors}
              label={"Email"}
              name="email"
              className="email-input"
            >
              <Input
                prefix={<Mail fill={theme["primary-color"]} />}
                maxLength={50}
              />
            </ValidatedFormItem>

            {spinner && (
              <div className="spinner">
                <Spin indicator={antIcon} size="large" />
              </div>
            )}
          </Col>
        </Row>
        <Button type="primary" htmlType="submit" className="send-btn">
          {"Send"}
        </Button>
      </Form>
    </div>
  );
};

export default ForgotPassword;
