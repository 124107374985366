import React, {
    FunctionComponent,
    useState,
    useEffect,
    useCallback,
    ReactNode,
    useRef,
} from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { useSearchDebounce, useService, useStores } from 'Hooks';
import FilterStore from 'Stores/FilterStore';
import { Layout, Table } from 'antd';
import { ColumnType, TablePaginationConfig } from 'antd/lib/table/interface';
import TableFilters from 'Components/table-filters/table-filters';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { ALL_TREATMENTS, DEFAULT_PAGE_SIZE } from 'Models/Constants';
import { GetPatientsSortColumnDto } from 'Api/Features/Patients/Dtos/GetPatientsSortColumnDto';
import { GetPatientsRequest, PatientService } from 'Services/PatientService';
import { PatientDto } from 'Api/Features/Patients/Dtos/PatientDto';
import { PlusSign, User } from 'Components/icons';
import { theme } from 'Style/theme';
import ListSectionHeader from 'Components/list-section-header/list-section-header';
import { TimeframeFilterEnum } from 'Filters/TimeframeFilter';
import LastMPNAnswerBadge from 'Components/last-mpn-answer-badge/last-mpn-answer-badge';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import './index.less';
import EditPatientModal from './id/edit-patient-modal';
import { PhysicianService } from 'Services/PhysicianService';
import { AnswerPhysicianRequestsRequestDto } from 'Api/Features/Physicians/Dtos/AnswerPhysicianRequestsRequestDto';
import { DiagnosisToString } from 'Models/Diagnosis/DiagnosisToString';
import { TreatmentTypeToString } from 'Models/TreatmentType/TreatmentTypeToString';
import { PhysicianPatientStatusDto } from 'Api/Features/Patients/Dtos/PhysicianPatientStatusDto';
import Tag, { TagColor, TagSize } from 'Components/tag/tag';
import DropdownButton, { DropdownButtonMenuItem } from 'Components/dropdown-button/dropdown-button';
import TransferPatientModal from 'Routes/authenticated/patients/id/transfer-patient-modal';
import SideSwippingCarousel from 'Components/SideSwippingCarousel';
import PatientInvitationCard from 'Components/SideSwippingCarousel/patient-invitation-card/patient-invitation-card';

const { Content } = Layout;

const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    showSizeChanger: true,
    position: ['bottomRight', 'topRight'],
};

const Patients: FunctionComponent = observer(() => {
    //#region Hooks
    const filterStoreRef = useRef(new FilterStore());
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [patients, setPatients] = useState<PatientDto[]>([]);
    const [pendingInvitations, setPendingInvitations] = useState<PatientDto[]>([]);
    const patientService = useService(PatientService);
    const physicianService = useService(PhysicianService);
    const history = useHistory();
    const [createPatientModalOpen, setCreatePatientModalOpen] = useState(false);
    const { userStore, globalLoadingStore, toastStore } = useStores();
    const [transferPatientsModalOpen, setTransferPatientsModalOpen] = useState(false);
    //#endregion

    //#region Table Content

    const columns: ColumnType<PatientDto>[] = [
        {
            key: GetPatientsSortColumnDto.LastName,
            title: 'Name',
            render: (patient: PatientDto): ReactNode | null => (
                <div>
                    {`${patient?.firstName} ${patient.lastName}`}{' '}
                    {patient.physicianPatientStatus === PhysicianPatientStatusDto.PatientInvitationPending ? (
                        <Tag
                            color={TagColor.Grey}
                            text={'Pending Invitation'}
                            size={TagSize.Small}
                        />
                    ) : null}
                </div>
            ),
            sorter: true,
        },
        {
            key: GetPatientsSortColumnDto.Diagnosis,
            title: 'Diagnosis',
            sorter: true,
            render: (patient: PatientDto): ReactNode | null =>
                patient.diagnosis ? DiagnosisToString(patient.diagnosis) : null,
        },
        {
            key: GetPatientsSortColumnDto.TreatmentType,
            title: 'Treatment',
            render: (patient: PatientDto): ReactNode | null =>
                patient.treatmentType ? TreatmentTypeToString(patient.treatmentType) : null,
            sorter: true,
        },
        {
            key: GetPatientsSortColumnDto.LastSubmissionDate,
            title: 'Last MPN 10 Answer',
            render: (patient: PatientDto): ReactNode => (
                <LastMPNAnswerBadge
                    lastAnswerDate={
                        patient.lastSubmissionDate
                            ? moment(patient.lastSubmissionDate).format()
                            : undefined
                    }
                />
            ),
            sorter: true,
        },
    ];

    const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any): void => {
        let sortDirection: SortDirectionDto | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirectionDto.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirectionDto.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        fetch({
            pagination,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
            searchTerm: filterStoreRef.current.searchTerm,
            treatmentTypes: filterStoreRef.current.treatmentType,
            timeframe: filterStoreRef.current.timeframe,
        });
    };
    //#endregion

    //#region Fetch & Effects

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            sortColumn: GetPatientsSortColumnDto | null;
            sortDirection: SortDirectionDto | null;
            searchTerm: string;
            treatmentTypes: any;
            timeframe: TimeframeFilterEnum;
        }) => {
            setLoading(true);
            try {
                const request: GetPatientsRequest = {
                    pageSize: params.pagination.pageSize || DEFAULT_PAGE_SIZE,
                    page: (params.pagination.current || 1) - 1,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    searchTerm: params.searchTerm,
                    treatmentTypes:
                        params.treatmentTypes === ALL_TREATMENTS ? [] : params.treatmentTypes,
                    timeframe: params.timeframe,
                    physicianPatientStatuses: [PhysicianPatientStatusDto.Accepted, PhysicianPatientStatusDto.PatientInvitationPending]
                };

                // call api
                const [patients, totalItems] = await patientService.getPhysicanPatients(
                    userStore.userInfo?.id ?? '',
                    request
                );
                setPatients(patients);

                setPagination({
                    ...params.pagination,
                    total: totalItems,
                });
            } finally {
                setLoading(false);
            }
        },
        [patientService]
    );

    const debouncedFetch = useSearchDebounce(fetch);
    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debouncedFetch({
                pagination: initialPaginationState,
                sortColumn: null,
                sortDirection: null,
                searchTerm: filterStore.searchTerm,
                treatmentTypes: filterStore.treatmentType,
                timeframe: filterStore.timeframe,
            });
        });
        return (): void => {
            disposer();
        };
    }, [debouncedFetch]);

    const fetchInvitations = useCallback(
        async () => {
            setLoading(true);
            try {
                const request: GetPatientsRequest = {
                    physicianPatientStatuses: [PhysicianPatientStatusDto.PhysicianRequestPending],
                    sortColumn: GetPatientsSortColumnDto.PhysicianRequestDate
                };

                // call api
                const [patients] = await patientService.getPhysicanPatients(
                    userStore.userInfo?.id ?? '',
                    request
                );
                setPendingInvitations(patients);
            } finally {
                setLoading(false);
            }
        },
        [patientService]
    );
    useEffect(() => {
        if(userStore.userInfo?.id)
            fetchInvitations() 
    }, [fetchInvitations, userStore.userInfo?.id])
    //#endregion

    const handleAcceptInvitationClick = async (id?: string) => {
        if (id && userStore.userInfo?.id) {
            const request: AnswerPhysicianRequestsRequestDto = {
                isAccepted: true,
                patientIds: [id],
            };
            try {
                globalLoadingStore.addLoading();
                await physicianService.answerPhysicianRequests(userStore.userInfo?.id, request);
                toastStore.toast({
                    type: 'success',
                    message: 'Patient request accepted successfully'
                })
                fetchInvitations();
                const filterStore = filterStoreRef.current;
                fetch({pagination: initialPaginationState,
                    sortColumn: null,
                    sortDirection: null,
                    searchTerm: filterStore.searchTerm,
                    treatmentTypes: filterStore.treatmentType,
                    timeframe: filterStore.timeframe,});
            } catch (e) {
                if (!e.treated) {
                    toastStore.serverError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        }
    };

    const handleDeclineInvitationClick = async (id?: string) => {
        if(id && userStore.userInfo?.id) {
            const request: AnswerPhysicianRequestsRequestDto = {
                isAccepted: false,
                patientIds: [id]
            }
            try {
                globalLoadingStore.addLoading();
                await physicianService.answerPhysicianRequests(userStore.userInfo?.id, request);
                toastStore.toast({
                    type: 'success',
                    message: 'Patient request declined successfully'
                })
                fetchInvitations();
            } catch (e) {
                if (!e.treated) {
                    toastStore.serverError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        }
    }

    const menuItems: DropdownButtonMenuItem[] = [
        {
            text: 'Create patient',
            icon: <PlusSign width={20} height={20} fill={theme['primary-color']} />,
            onClick: () => setCreatePatientModalOpen(true)
        },
        {
            text: 'Transfer All Patients',
            icon: <User width={20} height={20} fill={theme['primary-color']} />,
            onClick: () => setTransferPatientsModalOpen(true)
        },
    ];
    
    return (
        <div className="Patients">
            <ListSectionHeader
                title={'Patients'}
                icon={<User fill={theme['primary-color']} width={32} height={32} />}
                action={<DropdownButton menuItems={menuItems} />}
            />
            <Content>
                {pendingInvitations && pendingInvitations.length > 0 && (
                    <div className='patient-requests-container'>
                        <div className="patient-requests">{'Patient Requests'}</div>
                        <div className="invitations-container">
                            <div className="inner-container">
                                <div className="swipper-container">
                                    <SideSwippingCarousel
                                        elements={pendingInvitations.map((inv) => (
                                            <PatientInvitationCard
                                                key={inv.id || ''}
                                                data={inv}
                                                onAcceptClick={(id) =>
                                                    handleAcceptInvitationClick(id)
                                                }
                                                onDeclineClick={(id) =>
                                                    handleDeclineInvitationClick(id)
                                                }
                                            />
                                        ))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeTreatmentType
                    includeTimeframe
                />
                <Table
                    className="table-striped-rows table-action-rows"
                    bordered
                    columns={columns}
                    rowKey={(patient: PatientDto): string => patient.id!}
                    dataSource={patients}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(patient: PatientDto) => ({
                        onClick: () => {
                            history.push(`/patients/${patient.id}`);
                        },
                    })}
                />
            </Content>

            {createPatientModalOpen && (
                <EditPatientModal
                    visible={createPatientModalOpen}
                    onComplete={(success: Boolean): void => {
                        if (success)
                            fetch({
                                pagination: initialPaginationState,
                                sortColumn: null,
                                sortDirection: null,
                                searchTerm: filterStoreRef.current.searchTerm,
                                timeframe: TimeframeFilterEnum.All,
                                treatmentTypes: ALL_TREATMENTS,
                            });
                        setCreatePatientModalOpen(false);
                    }}
                />
            )}

            {transferPatientsModalOpen && (
                <TransferPatientModal
                    visible={transferPatientsModalOpen}
                    onComplete={(success: boolean): void => {
                        if (success)
                            fetch({
                                pagination: initialPaginationState,
                                sortColumn: null,
                                sortDirection: null,
                                searchTerm: filterStoreRef.current.searchTerm,
                                timeframe: TimeframeFilterEnum.All,
                                treatmentTypes: ALL_TREATMENTS,
                            });
                        setTransferPatientsModalOpen(false);
                    }}
                    currentDoctorId={userStore.userInfo?.id}
                    transferAllPatients
                />
            )}
        </div>
    );
});

export default Patients;
