import React, { FunctionComponent, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import './patient-other-questionnaire-history.less';
import moment from 'moment';
import { useEffect } from 'react';
import { SurveyResponseDto } from 'Api/Features/SurveyResponses/Dtos/SurveyResponseDto';
import PatientOtherQuestionnaireHistoryCollapse from 'Components/patient-other-questionnaire-history-collapse/patient-other-questionnaire-history-collapse';
import SurveyQuestionExplanationModal from 'Components/survey-question-explanation-modal/survey-question-explanation-modal';
import { QuestionDto } from 'Api/Features/Surveys/Dtos/QuestionDto';
import { groupByMonth } from 'Utils/SurveyResponseDtoUtils';

interface PatientOtherQuestionnaireHistoryProps {
    data?: SurveyResponseDto[];
}

interface QuestionModalState {
    visible: boolean;
    question?: QuestionDto
} 

interface PatientHistoryCollapseExpandState {
    index: number;
    isExpanded: boolean;
}

const PatientOtherQuestionnaireHistory: FunctionComponent<PatientOtherQuestionnaireHistoryProps> = observer(({ data }) => {
    const [expandedStates, setExpandedStates] = useState<PatientHistoryCollapseExpandState[]>([]);
    const [surveyQuestionExplanationModal, setSurveyQuestionExplanationModal] =
        useState<QuestionModalState>({
            visible: false,
            question: undefined
        });

    const orderedData = useCallback((): SurveyResponseDto[] => {
        if (data && data?.length > 0) {
            const orderedByDate = data
                .sort((a, b) => (moment(b.date) as any) - (moment(a.date) as any));
            return orderedByDate;
        }
        else {
            return [];
        }
    }, [data])

    const handleExpandClick = (index: number, isExpanded: boolean): void => {
        const oldState = [...expandedStates];
        oldState[index].isExpanded = isExpanded;
        setExpandedStates(oldState);
    };

    useEffect(() => {
        setExpandedStates(
            data?.map(
                (_, i) => ({ index: i, isExpanded: false } as PatientHistoryCollapseExpandState)
            ) ?? []
        );
    }, [data]);

    return (
        <div className="PatientOtherQuestionnaireHistory">
            <div className="history-container">
                {expandedStates.length > 0 &&
                    groupByMonth(orderedData()).map((group) => {
                        return (
                            <div key={group.groupString}>
                                <div className="month-title">{group.groupString}</div>

                                {group.surveys.map((item, i) => (
                                    <PatientOtherQuestionnaireHistoryCollapse
                                        key={`${item.id} + ${item.date}`}
                                        data={item}
                                        onSymptomTooltipClick={(question?: QuestionDto) =>
                                            setSurveyQuestionExplanationModal({
                                                visible: true,
                                                question
                                            })
                                        }
                                        isExpanded={expandedStates[i]?.isExpanded}
                                        index={i}
                                        onExpandClick={(index: number, isExpanded: boolean) =>
                                            handleExpandClick(index, isExpanded)
                                        }
                                    />
                                ))}
                            </div>
                        );
                    })}
            </div>

            {surveyQuestionExplanationModal && (
                <SurveyQuestionExplanationModal
                    visible={surveyQuestionExplanationModal.visible}
                    question={surveyQuestionExplanationModal.question}
                    onClose={() =>
                        setSurveyQuestionExplanationModal({ visible: false, question: undefined})
                    }
                />
            )}
        </div>
    );
});

export default React.memo(PatientOtherQuestionnaireHistory);

