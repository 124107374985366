import React, { FunctionComponent } from "react";
import { Row, Col } from "antd";
import { observer } from "mobx-react";
import Appstore from 'Assets/Images/app-store.svg';
import GooglePlay from 'Assets/Images/google-play.svg';
import './password-confirmed.less';
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from "Models/Constants";

const PasswordConfirmed: FunctionComponent = observer(() => {

  return (
      <div className="PasswordConfirmed">
          <Row className="heading">
              <Col className="H2-bold">{"It's Updated!"}</Col>
          </Row>

          <Row className="heading">
              <Col className="Paragraph">{'Your password has been successfully updated.'}</Col>
          </Row>

          <Row>
              <Col className="appstores">
                  <img
                      src={Appstore}
                      alt={'app store'}
                      onClick={() => window.open(APP_STORE_LINK, '_blank', 'noopener,noreferrer')}
                      className="app-store"
                  />

                  <img
                      src={GooglePlay}
                      alt={'app store'}
                      onClick={() => window.open(GOOGLE_PLAY_LINK, '_blank', 'noopener,noreferrer')}
                  />
              </Col>
          </Row>
      </div>
  );
});

export default PasswordConfirmed;
