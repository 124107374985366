import React, { FunctionComponent, useCallback } from 'react';
import { Button, Layout } from 'antd';
import ListSectionHeader from 'Components/list-section-header/list-section-header';
import { useStores } from 'Hooks';
import './index.less';
import { useState } from 'react';
import EditProfile from './edit-profile';
import ProfileInformationCard from 'Components/profile-information-card/profile-information-card';
import { TagColor } from 'Components/tag/tag';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';

const { Content } = Layout;

const Profile: FunctionComponent = () => {
    const { globalLoadingStore, userStore } = useStores();
    const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);

    const userRole = useCallback((): ManagementRoleDto | null | undefined => {
        return userStore.userInfo?.managementRoles?.[0]?.name;
    }, [])

    const fetch = useCallback(async () => {
        globalLoadingStore.addLoading();
        try {
            userStore.setUserInfo();
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore]);

    return (
        <div className="Profile">
            <ListSectionHeader
                action={
                    <Button type="primary" onClick={() => setEditProfileModalOpen(true)}>
                        {'Edit Profile'}
                    </Button>
                }
            />
            <Content>
                <ProfileInformationCard
                    firstName={userStore.userInfo?.firstName}
                    lastName={userStore.userInfo?.lastName}
                    email={userStore.userInfo?.email}
                    phoneNumber={userStore.userInfo?.phoneNumber}
                    isDoctorProfile={userRole() === ManagementRoleDto.Physician}
                    tagprops={
                        userRole() === ManagementRoleDto.Physician
                            ? { text: 'Doctor', color: TagColor.Purple }
                            : userRole() === ManagementRoleDto.Administrator
                            ? { text: 'Administrator', color: TagColor.Purple }
                            : userRole() === ManagementRoleDto.SuperAdministrator
                            ? { text: 'Super Administrator', color: TagColor.Green }
                            : undefined
                    }
                    imageUrl={userStore.userInfo?.imageUrl ?? undefined}
                />
            </Content>

            {editProfileModalOpen && userStore.userInfo && (
                <EditProfile
                    visible={editProfileModalOpen}
                    onComplete={(success: boolean) => {
                        if (success) fetch();
                        setEditProfileModalOpen(false);
                    }}
                    user={userStore.userInfo}
                    isDoctor={userRole() === ManagementRoleDto.Physician}
                />
            )}
        </div>
    );
};

export default Profile;
