import React, {FunctionComponent} from 'react';
import NoProfilePicture from 'Assets/Images/no-profile.svg';
import './image-picker.less';

interface ImagePickerProps {
    imageUrl?: string;
    onFileChange: (imageUrl: string, base64?: string) => void;
}

const ImagePicker: FunctionComponent<ImagePickerProps> = ({ imageUrl, onFileChange }) => {
    const fileInputRef = React.createRef<HTMLInputElement>();

    const handleEditClick = () => {
        fileInputRef.current?.click();
    }

    const onchange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();

            reader.onload = async function (e) {
                const imageUrl = e.target?.result as string;
                const base64 = imageUrl.substring(imageUrl.lastIndexOf(',') + 1);
                onFileChange(imageUrl, base64);
            };

            reader.readAsDataURL(event.target.files[0]);
        }
    }

    return (
        <div className="ImagePicker">
            <div className="image-container" style={{ backgroundImage: `url(${imageUrl ?? NoProfilePicture})` }} />
            <div className="edit-button-container">
                <span className="edit-button" onClick={() => handleEditClick()}>{imageUrl ? "Edit image" : "Choose an image"}</span>
                <input ref={fileInputRef}  className="file-input" type="file" accept="image/*" onChange={(e) => onchange(e)}/>
            </div>
        </div>
    );
};

export default React.memo(ImagePicker);