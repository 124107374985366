import { Modal, Typography } from 'antd';
import { Close } from 'Components/icons';
import { MPNSymptom } from 'Models/Mpn10Question/Mpn10QuestionMap';
import React, { FunctionComponent } from 'react';
import { theme } from 'Style/theme';
import './symptom-explanation-modal.less';

const { Title } = Typography;

export interface ConfirmationModalProps {
    title: MPNSymptom;
    onClose: () => void;
    visible: boolean;
}

const SymptomExplanationModal: FunctionComponent<ConfirmationModalProps> = ({
    title,
    onClose,
    visible,
}) => {
    const description = (): string => {
        switch (title) {
            case MPNSymptom.Fatigue:
                return 'Please rate your fatigue (weariness, tiredness) by selecting the one number that best describes your WORST level of fatigue during past 24 hours.';
            default:
                return 'The patient selected a number that describes how much difficulty they have had with this symptom.';
        }
    };

    return (
        <Modal
            visible={visible}
            className="SymptomExplanationModal"
            width={780}
            onCancel={onClose}
            closeIcon={<Close fill={theme.black} />}
        >
            <Title level={2} className="title">
                {title}
            </Title>
            <div className="description">{description()}</div>

            <div className="scale-container">
                <div className="scale">
                    <div className="number">{'0'}</div>
                    <div className="text">{'(absent)'}</div>
                </div>

                <div className="up-to">{'Up To'}</div>

                <div className="scale">
                    <div className="number">{'10'}</div>
                    <div className="text">{'(worst imaginable)'}</div>
                </div>
            </div>
        </Modal>
    );
};

export default SymptomExplanationModal;
