import { TreatmentTypeDto } from "Api/Features/Patients/Dtos/TreatmentTypeDto";


export const TreatmentTypeToString = (diagnosis: TreatmentTypeDto): string => {
    switch (diagnosis) {
        case TreatmentTypeDto.Aspirin:
            return 'Aspirin';
        case TreatmentTypeDto.JakInhibitor:
            return 'JAK inhibitor';
        case TreatmentTypeDto.NonJakInhibitor:
            return 'Non JAK inhibitor';
        case TreatmentTypeDto.Phlebotomies:
            return 'Phlebotomies';
        case TreatmentTypeDto.WatchAndWait:
            return 'Watch and Wait';
        case TreatmentTypeDto.Interferons:
            return 'Interferons';
        default:
            return '';
    }
} 